import { ReactNode } from 'react';

import Dropdown, { DropdownProps } from 'components/Dropdown/Dropdown';
import { InfoIcon } from 'components/Icons';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { ClassNames } from 'types';
import { classes } from 'utils';

interface FormTextInputProps extends DropdownProps {
  name: string;
  placeholder?: string;
  label?: string;
  labelIcon?: ReactNode;
  shouldUnregister?: boolean;
  tooltip?: string;
  classNames?: ClassNames<'label' | 'dropBorder'>;
  hideError?: boolean;
}

export const FormDropdown = ({
  classNames,
  name,
  label,
  placeholder,
  options,
  mobileLabel,
  labelIcon,
  shouldUnregister = true,
  tooltip,
  extraOption,
  hideError = false,
  ...rest
}: FormTextInputProps) => {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
        <div>
          <Tooltip id={name} place="bottom" content={tooltip} />
          <label
            htmlFor="tokenType"
            className={classes(
              'pb-[9px] text-sm font-medium flex items-center justify-between text-lightBlack leading-[22px]',
              classNames?.label
            )}
          >
            {label}
            {tooltip && (
              <InfoIcon className="cursor-pointer" data-tooltip-id={name}>
                {labelIcon}
              </InfoIcon>
            )}
          </label>
          <div
            className={classes(
              'border border-lightBlack/[0.15] !mt-0 bg-white rounded-full md:py-[15px] py-3',
              classNames?.dropBorder,
              error && !hideError ? 'border-red-500' : ''
            )}
          >
            <Dropdown
              extraOption={extraOption}
              mobileLabel={label || mobileLabel}
              options={options}
              placeholder={placeholder}
              onSelect={(value) => onChange(value)}
              defaultSelected={restField.value}
              classNames={{
                selector: 'p-0 h-auto justify-between md:px-5 px-4 w-full text-base',
                base: 'w-full',
                container: 'border-lightBlack/[0.17] mt-[30px] rounded-t-2xl md:rounded-2xl',
                option: 'text-lightBlack p-0 first:md:pt-0 md:pb-4 last:md:pb-0',
                optionContainer: 'gap-4',
              }}
              {...rest}
              {...restField}
            />
          </div>
          {error && !hideError && (
            <span className="text-sm text-error flex gap-1 mt-2">
              <img src="/closeCircle.svg" alt="bodyYellowBg" />
              {error.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default FormDropdown;

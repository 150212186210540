import React from 'react';

import { LeftArrowIcon } from 'components/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { classes } from 'utils';

import { BreadcrumbProps } from './type';

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <nav className={classes('flex items-center text-sm text-gray-600 gap-3', className)}>
      <button type="button" onClick={handleBackClick} className="flex items-center hover:underline">
        <LeftArrowIcon />
      </button>

      {items.map((item, index) => {
        const isLastItem = items.length === index + 1;

        return (
          <React.Fragment key={item.label}>
            {!!index && <span className="text-[#999]">/</span>}
            <Link
              to={item.path || ''}
              className={classes(
                `text-sm font-medium ${
                  isLastItem
                    ? 'text-[#999] flex-shrink-0 pointer-events-none'
                    : 'hover:underline text-lightBlack flex-shrink-0'
                } `,
                items.length === 1 ? 'text-lightBlack' : ''
              )}
            >
              {item.label}
            </Link>
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;

import { Dropdown } from 'components';
import { CardPlus } from 'components/Icons/CardPlus';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import { AddCollectionCard, AddCollectionModal, ExistingCollectionCard } from './components';
import useMyCollection from './useMyCollection';
import { chainsOptions, filtersStyle, pricingOptions, sourceOptions } from './utils';

export const MyCollections = () => {
  const { isModalOpen, setModalOpen } = useMyCollection();
  const navigate = useNavigate();
  const items = Math.round(Math.random());

  return (
    <>
      <AddCollectionModal isOpen={isModalOpen} setIsOpen={setModalOpen} />
      <div>
        <div className="flex flex-col md:flex-row justify-between md:pt-12 pt-14 md:pb-6 pb-4">
          <div className="w-full md:w-[30%]">
            <div className="uppercase font-secondary md:text-[40px] text-[32px] leading-none">
              All Collections
            </div>
            <div className="text-base text-textGray">{items} items</div>
          </div>
          <div className="flex flex-col md:flex-row justify-end md:flex-wrap gap-2 my-4 md:my-0 w-full md:w-[70%]">
            <Dropdown
              options={sourceOptions}
              placeholder="Source"
              mobileLabel="Source"
              defaultSelected={sourceOptions[0]}
              hideOptionsIcon
              classNames={filtersStyle}
            />
            <Dropdown
              options={chainsOptions}
              placeholder="All Chains"
              mobileLabel="All Chains"
              defaultSelected={chainsOptions[0]}
              hideOptionsIcon
              classNames={filtersStyle}
            />
            <Dropdown
              options={pricingOptions}
              placeholder="High to Low"
              mobileLabel="High to Low"
              defaultSelected={pricingOptions[0]}
              hideOptionsIcon
              classNames={filtersStyle}
            />
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <AddCollectionCard
            cardTheme="light"
            onClick={() => {
              setModalOpen(true);
            }}
            className="cursor-pointer w-auto max-w-[448px] min-w-[300px]"
            classNames={{
              subtext: 'md:!text-[24px] !text-[18px] font-semibold leading-[32px]',
              button: 'md:w-12 w-10 md:h-12 h-10',
            }}
            title="ready to create"
            subtext="Create a new Collection or Import Existing"
            button={<CardPlus fill="#fff" />}
          />
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <ExistingCollectionCard
                  key={index}
                  classNames={{
                    base: 'p-0 w-auto max-w-[448px] min-w-[300px]',
                    link: 'm-0',
                    linkAction: 'justify-start text-base',
                    heading: 'font-primary text-[18px] md:text-[24px]',
                  }}
                  collectionName="Waste Collection"
                  title="hlrg"
                  items="8"
                  tokenType="ERC-721"
                  cardFooter={{
                    text: 'VIEW NFTS',
                    action: () => navigate(`/${routes.nftHub}/123`),
                  }}
                  cardProps={{ classNames: { imgCard: 'flex flex-col justify-between' } }}
                  hideBackgroundImage
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MyCollections;

import { useState } from 'react';

import Button from 'components/Button/Button';
import { PixelportCloseIcon } from 'components/Icons/PixelportCloseIcon';
import { PixelportRightArrow } from 'components/Icons/PixelportRightArrow';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes } from 'utils';

import { UserProfile } from './components/UserProfile/UserProfile';

const newMenu = [
  {
    path: `/${routes.nftHub}`,
    label: 'NFT Hub',
  },
  {
    path: `/${routes.bridge}`,
    label: 'Bridge',
  },
  // {
  //   path: `/${routes.fractionalize}`,
  //   label: 'Fractionalize',
  // },
];

// For Pixelport APP
export function SubHeader() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const MenuItem = () => {
    return (
      <>
        {newMenu.map(({ path, label }) => {
          return (
            <li key={path} className="list-none">
              <NavLink
                to={path}
                className={({ isActive }) =>
                  classes(
                    'z-[2] relative font-Jakarta  uppercase text-base block leading-[normal]',
                    isMenuOpen
                      ? 'flex items-center justify-between border-b border-[#E3E3E366] p-4 pr-5'
                      : 'py-3 lg:mx-6 mx-3',
                    isActive && 'md:bg-white font-bold',
                    !isMenuOpen &&
                      isActive &&
                      "relative before:content-[''] before:absolute before:bottom-[-0px] before:border-b-[2px] before:w-[16px] before:left-1/2 before:-translate-x-1/2"
                  )
                }
                aria-current="page"
                onClick={() => setIsMenuOpen(false)}
              >
                {label}
                {isMenuOpen && <PixelportRightArrow />}
              </NavLink>
            </li>
          );
        })}
      </>
    );
  };

  const CloseButton = () => {
    return (
      <button
        className={classes(
          'relative z-[2] -top-[2px] block pr-[3px] cursor-pointer md:hidden ',
          !isMenuOpen && 'h-6 w-6'
        )}
        onClick={() => setIsMenuOpen((prev) => !prev)}
        aria-label="menu"
      >
        {!isMenuOpen && (
          <div
            className={classes(
              "transition-menu-button before:transition-menu-button after:transition-menu-button absolute h-[2px] w-5 bg-black duration-300 ease-in-out before:absolute before:-top-2 before:block before:h-[2px] before:w-5 before:bg-black before:duration-300 before:ease-in-out before:content-[''] after:absolute after:top-2 after:block after:h-[2px] after:w-5 after:bg-black after:duration-300 after:ease-in-out after:content-['']"
            )}
          />
        )}
        {isMenuOpen && (
          <div className="mr-1">
            <PixelportCloseIcon />
          </div>
        )}
      </button>
    );
  };

  return (
    <div className="bg-white border-b border-b-[#e3e3e3]/[0.32] md:border-none w-full flex items-center justify-center">
      <div className="flex justify-between items-center px-5 md:px-4 md:pl-7 py-3 md:mt-[20px] w-full max-w-[100%] md:max-w-[97%] 2xl:max-w-[75%] md:border md:border-[#83838333] md:rounded-full md:shadow-[0px_4px_17.7px_0px_#0000000F]">
        <div className="flex items-center gap-8 my-5 md:my-0">
          <Link
            className="leading-[0px] uppercase relative z-[2] text-[32px] lg:text-[32px] font-secondary translate-y-1"
            to="/"
          >
            Pixelport
          </Link>

          <div className="rounded-full md:block hidden">
            <ul className="flex font-secondary uppercase text-[22px] leading-[normal]">
              <div className={classes('md:flex hidden items-center flex-1 justify-end')}>
                <div className={classes('flex justify-start')}>
                  <MenuItem />
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="md:flex flex-row gap-4 hidden">
          <Button onClick={() => navigate('/')} variant="outlined" color="black">
            Documentation
          </Button>
          <UserProfile />
        </div>
        <CloseButton />

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="fixed top-0 left-0 z-20 w-full h-full md:hidden">
            <div className="bg-white w-full h-full rounded-[10px] relative">
              <div
                className="w-full h-full rounded-[10px] !bg-cover !bg-[84%]"
                style={{
                  background:
                    'linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgb(247 247 247 / 71%) 100%), url(./newDesign/images/bgCloud.png)',
                  backgroundBlendMode: 'luminosity',
                }}
              >
                <img src="/topBlurBg.svg" alt="topBlurBg" className="absolute z-[-1]" />
                <div className="flex items-center justify-between px-4 py-5 bg-white border-b border-b-[#e3e3e3]/[0.32]">
                  <Link
                    to={routes.base}
                    className="leading-none uppercase relative z-[2] text-[32px] lg:text-[44px] font-secondary"
                  >
                    Pixelport
                  </Link>
                  <CloseButton />
                </div>
                <div className="flex flex-col h-full">
                  <MenuItem />
                  <div className="flex flex-row items-center w-full absolute bottom-6 top-auto z-10 gap-4 px-4">
                    <Button
                      onClick={() => navigate('/')}
                      className="!leading-normal text-[14px] lg:!text-[22px] justify-center mx-auto w-full"
                      color="black"
                      variant="outlined"
                    >
                      documentation
                    </Button>
                    <UserProfile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubHeader;

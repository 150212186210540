import { useEffect, useRef, useState } from 'react';

import { Header, TypeFormButton } from 'components';
import { ContactsIcon, FramesIcon, TransferNFTIcon } from 'components/Icons';
import { useWindowSize } from 'hooks';
import { classes } from 'utils';

import { GetStartedButton } from './GetStartedButton';

const FeaturesData = [
  {
    text: 'Mint Cross-Chain NFTs',
    subText:
      'With Pixelmint, you can mint cross-chain NFTs or supercharge your existing ones with cross-chain features - There are no boundaries! It’s fast, fun, and totally secure. Why stay on one chain when you can own them all?',
    Icon: ContactsIcon,
    bgImage: 'newDesign/GIFs/mintCrossChain.gif',
  },
  {
    text: 'Fractionalize NFTs',
    subText:
      "Turn your NFTs into bite-sized investments with Pixelport. Fractionalize and make them accessible to everyone. Whether you're looking to share ownership or just want to make your assets more liquid, Pixelport gives you tools to maximize your NFT's potential.",
    Icon: FramesIcon,
    bgImage: 'newDesign/GIFs/fractionalizeNFTs.gif',
  },
  {
    text: 'Transfer NFTs Cross-Chain',
    subText:
      'Why keep your NFTs locked in the blockchain silos? Pixelport allows you to break down the walls and move your assets across any supported blockchain tapping into new markets and liquidity. It’s your collection—get it where it can do the most good and make the biggest impact',
    Icon: TransferNFTIcon,
    bgImage: 'newDesign/GIFs/transferCrossChain.gif',
  },
];

// eslint-disable-next-line import/no-unused-modules
export function KeyFeatures() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;

  const [isInView, setIsInView] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.1, // 10% of the div needs to be visible to trigger
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <div
      className="lg:flex flex-col xl:flex-row lg:gap-[78px] md:gap-[30px] gap-8 items-start md:justify-between w-full"
      id="key-features"
    >
      <div className="flex flex-col gap-2 md:gap-3 lg:gap-8 max-w-full lg:max-w-[550px] mt-16 z-10 px-6 xxs:px-10 pb-8 xl:sticky top-[5%] xl:mb-[400px]">
        <Header
          tag={'h2'}
          classNames={{
            header:
              'text-5xl md:text-[64px] lg:min-w-[600px] leading-[48px] font-["Arcade"] normal-case pixelport-text-shadow uppercase',
          }}
        >
          Key Features
        </Header>
        <Header
          tag={'p'}
          classNames={{
            header:
              'text-base leading-[24px] md:text-lg normal-case font-Jakarta font-normal z-10 relative',
          }}
        >
          At Pixelport, we're making NFT creation and trading as fun and accessible as your favorite
          game. With cross-chain capabilities and fractionalization, Pixelport empowers you to
          unlock endless possibilities across any blockchain effortlessly. Ready to play?"
        </Header>
        <TypeFormButton formId="uvT7QzSD" as="div">
          <GetStartedButton
            title="get early access"
            arrowStroke="black"
            classNames={{
              base: '!bg-black !text-white z-[100] relative',
              container: 'bg-white',
              text: 'font-Jakarta font-semibold',
            }}
          />
        </TypeFormButton>
      </div>
      <div
        ref={divRef}
        className={`grid grid-rows-[repeat(${FeaturesData.length},_40vw)] gap-[42px] px-4 xxs:px-8 mt-4 pb-8`}
      >
        {FeaturesData.map(({ subText, text, Icon, bgImage }, index) => {
          return (
            <div
              key={`feature-${index}`}
              className={classes(
                'w-full sm:w-[75%] xl:w-[500px] min-h-[440px] md:min-h-[644px] rounded-[16px] px-[30px] md:px-[50px] py-[30px] md:py-[38px] shadow-navbar-shadow border border-[#83838333] bg-white',
                'sticky z-10 md:bg-white md:bg-opacity-70 md:backdrop-blur-[10px]',
                index === 0 ? 'backdrop-blur-[10px] bg-opacity-70' : ''
              )}
              style={{
                // top: isBelowMd ? `${10 + index * 64}px` : `${10 + index * 100}px`,
                top: isBelowMd ? `${10 + index * 90}px` : `${10 + index * 90}px`,
                backgroundImage: isInView ? `url(./${bgImage})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: index === 0 ? '100%' : '90%',
                backgroundSize: index === 0 ? '100%' : '80%',
              }}
            >
              <div className="flex items-center gap-[14px] md:gap-[21px] pb-[12px] md:pb-[30px]">
                <div className="max-h-[24px] max-w-[24px]">
                  <Icon />
                </div>
                <div className="text-lg md:text-2xl font-semibold font-Jakarta ">{text}</div>
              </div>
              <div className="text-[14px] md:text-lg font-Jakarta font-normal mt-[14px] md:mt-[0px]">
                {subText}
              </div>
            </div>
          );
        })}
        <div className="py-2"></div>
      </div>
    </div>
  );
}

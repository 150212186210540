// StepOne
export const STEP_ONE_FIELD = 'stepOne';
export const ERC721_address_FIELD = 'ERC721_address';

// StepTwo
export const STEP_TWO_FIELD = 'stepTwo';
export const COLLECTION_NAME_FIELD = 'collectionName';
export const COLLECTION_SYMBOL_FIELD = 'collectionSymbol';
export const TOKEN_TYPE_FIELD = 'tokenType';

// StepThree
export const STEP_THREE_FIELD = 'stepThree';
export const FRACTIONS_FIELD = 'fractions';

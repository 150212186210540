import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';
import { getAllErrorMessages } from 'utils/getAllErrorMessages';

import { CreateBridgeNFTFormType } from '../../CreateBridgeNFTSchema';
import { STEP_ONE_FIELD } from '../../formFields';

export const useBridgeNFTStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<CreateBridgeNFTFormType>();

  const allErrors = getAllErrorMessages(errors.stepOne);

  const onNext = async () => {
    const isValid = await trigger(STEP_ONE_FIELD);

    if (isValid) {
      handleActiveStep((step) => step + 1);
    }
  };

  return { watch, setValue, onNext, allErrors };
};

import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';

import { STEP_TWO_FIELD } from '../../formFields';
import { CreateNFTFormType } from '../../WizardSchemaForCreateNFT';

export const useMintNFTStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { trigger, watch } = useFormContext<CreateNFTFormType>();

  // const image = watch(`${STEP_ONE_FIELD}.${UPLOAD_NFT_FIELD}`);

  // const urlImage = image?.[0] && URL.createObjectURL(image?.[0]);

  const urlImage = '/demo/bald_style.png';

  const onNext = async () => {
    const isValid = await trigger(STEP_TWO_FIELD);

    if (isValid) {
      handleActiveStep((step) => step + 1);
    }
  };

  return {
    onNext,
    urlImage,
  };
};

import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

export const useCollection = () => {
  const navigate = useNavigate();
  const { collectionId } = useParams();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeployModalOpen, setDeployModalOpen] = useState(false);
  const [isCongratulationsModalOpen, setCongratulationsModalOpen] = useState(false);

  const collection = {
    name: 'Collection Name',
    count: Math.round(Math.random()),
  };

  const breadcrumbData = [
    {
      path: `/${routes.nftHub}`,
      label: 'Collections',
    },
    {
      path: `/${routes.nftHub}/${collectionId}`,
      label: collection?.name,
    },
  ];

  return {
    navigate,
    breadcrumbData,
    isModalOpen,
    setModalOpen,
    isDeployModalOpen,
    setDeployModalOpen,
    isCongratulationsModalOpen,
    setCongratulationsModalOpen,
    collection,
    collectionId,
  };
};

import { useState } from 'react';

function useMyCollection() {
  const [isModalOpen, setModalOpen] = useState(false);

  return {
    isModalOpen,
    setModalOpen,
  };
}

export default useMyCollection;

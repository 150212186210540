import { ReactNode } from 'react';

import { InfoIcon } from 'components/Icons';
import { Controller } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { classes } from 'utils';

interface FormTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  label: string;
  labelIcon?: ReactNode;
  shouldUnregister?: boolean;
  tooltip?: string;
}

export const FormTextInput = ({
  name,
  label,
  disabled,
  labelIcon,
  shouldUnregister = true,
  tooltip,
  ...rest
}: FormTextInputProps) => {
  return (
    <Controller
      name={name}
      disabled={Boolean(disabled)}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, ...restField }, fieldState: { error } }) => {
        return (
          <div className="flex flex-col">
            <ReactTooltip
              id={name}
              place="bottom"
              content={tooltip}
              className="max-w-[264px] !rounded-xl bg-lightBlack text-white/[0.72] !text-xs !font-medium"
            />
            <label
              htmlFor={name}
              className="flex items-center justify-between mb-[9px] text-sm font-medium text-lightBlack leading-[22px]"
            >
              {label}
              {tooltip && (
                <InfoIcon className="cursor-pointer" data-tooltip-id={name}>
                  {labelIcon}
                </InfoIcon>
              )}
            </label>
            <input
              id={name}
              className={classes(
                'border border-lightBlack/[0.15] rounded-full md:px-5 px-4 md:py-[15px] py-3 focus:outline-lightBlack focus:ring-0',
                error ? 'border-error' : ''
              )}
              value={value || ''}
              {...rest}
              {...restField}
            />
            {error && (
              <span className="text-sm text-error flex gap-1 mt-2">
                <img src="/closeCircle.svg" alt="bodyYellowBg" />
                {error.message}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormTextInput;

import { zodResolver } from '@hookform/resolvers/zod';
import { Breadcrumb, Button } from 'components';
import Form from 'components/Form';
import { CardPlus } from 'components/Icons';
import { Attachment } from 'components/Icons/Attachment';
import LinkArrow from 'components/Icons/LinkArrow';
import { routes } from 'routes/routesConst';
import { z } from 'zod';

import { AddCollectionCard, ExistingCollectionCard } from '../components/MyCollections/components';

import { CongratulationsModel, DeployCollectionModel, MintNFTModal } from './components';
import { useCollection } from './useCollection';

const networkSelectionSchema = z.object({
  chain: z
    .string({ required_error: 'Please select relevant network' })
    .min(1, 'Please select relevant network'),
});

export const Collection = () => {
  const {
    navigate,
    breadcrumbData,
    isModalOpen,
    setModalOpen,
    isDeployModalOpen,
    setDeployModalOpen,
    isCongratulationsModalOpen,
    setCongratulationsModalOpen,
    collection,
    collectionId,
  } = useCollection();

  return (
    <div>
      <Form resolver={zodResolver(networkSelectionSchema)} action="/hello">
        <DeployCollectionModel
          isOpen={isDeployModalOpen}
          setIsOpen={setDeployModalOpen}
          isCloseHidden={true}
          setDeployModalOpen={setDeployModalOpen}
          setCongratulationsModalOpen={setCongratulationsModalOpen}
        />
        <CongratulationsModel
          isOpen={isCongratulationsModalOpen}
          setIsOpen={setCongratulationsModalOpen}
          isCloseHidden={true}
        />
        <MintNFTModal isOpen={isModalOpen} setIsOpen={setModalOpen} isCloseHidden={true} />
      </Form>
      <Breadcrumb items={breadcrumbData} className="my-5 md:my-8" />
      <div className="justify-between md:flex">
        <div>
          <div className="md:text-5xl text-[32px] md:leading-10 leading-8 font-bold font-secondary uppercase md:mb-3 mb-1">
            {collection?.name || 'Collection Name'}
          </div>
          <div className="text-textGray">{collection?.count || '0'} items</div>
        </div>
        <div className="flex gap-4 justify-between">
          <Button
            onClick={() => setDeployModalOpen(true)}
            variant="outlined"
            color="black"
            endIcon={
              <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 bg-black rounded-full">
                <LinkArrow className="w-6 h-6" fill="#fff" />
              </div>
            }
            className="!pr-1 !pl-6 !gap-5 md:!py-7 justify-between md:mt-6 mt-4 bg-transparent md:w-auto w-[174px] [&>div]:hover:text-black [&>div]:hover:invert"
          >
            Deploy
          </Button>
          <Button
            variant="contained"
            color="black"
            onClick={() => navigate(`/${routes.nftHub}/${collectionId}/${routes.createNFT}`)}
            endIcon={
              <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-white">
                <CardPlus className="text-black" />
              </div>
            }
            className="!pr-1 md:!pl-6 !pl-4 md:!gap-5 md:!py-7 justify-between md:mt-6 mt-4 md:w-full w-[152px] whitespace-nowrap"
          >
            Create NFT
          </Button>
        </div>
      </div>
      {collection.count ? (
        <div className="flex flex-col gap-4 mt-12 md:flex-row">
          <div className="flex-col gap-4 hidden">
            <AddCollectionCard
              cardTheme="light"
              onClick={() => navigate(`/${routes.nftHub}/${collectionId}/${routes.createNFT}`)}
              className="w-auto max-w-[332px] min-w-[300px] h-[243px]"
              classNames={{
                subtext: 'md:!text-[32px] !text-2xl !leading-none',
                button: 'md:w-12 w-10 md:h-12 h-10',
              }}
              title="Turn your imagination"
              subtext="Create a New NFT and Start Your Journey"
              button={<CardPlus />}
              cardProps={{ classNames: { imgCard: 'flex flex-col justify-between' } }}
            />
            <AddCollectionCard
              cardTheme="light"
              onClick={() => setDeployModalOpen(true)}
              className="w-auto max-w-[332px] min-w-[300px] h-[168px]"
              classNames={{
                subtext: 'md:!text-[32px] !text-2xl !leading-none',
                button: 'md:w-12 w-10 md:h-12 h-10',
              }}
              title="create your digital legacy"
              subtext="Deploy Your NFT Instantly"
              button={<Attachment />}
              cardProps={{ classNames: { imgCard: 'flex flex-col justify-between' } }}
            />
          </div>
          <ExistingCollectionCard
            classNames={{
              base: 'p-0 w-auto md:max-w-[332px] md:min-w-[332px]',
              link: 'md:rounded-full bg-[#FFFFFF80] text-black',
              linkAction: 'text-black text-[16px] font-semibold',
            }}
            collectionName="NFT NAME"
            mintedDate="24 JUN  2024"
            title="Network Name"
            featureImage="/demo/bald_style.png"
            cardFooter={{ text: 'Minted' }}
            chains={['ethereum', 'polygon']}
            hideBackgroundImage
          />
          <ExistingCollectionCard
            classNames={{
              base: 'p-0 w-auto md:max-w-[332px] md:min-w-[332px]',
              link: 'md:rounded-full bg-white text-black',
              linkAction: 'text-[16px] text-black font-semibold',
            }}
            collectionName="NFT NAME"
            mintedDate="24 JUN  2024"
            title="Network Name"
            featureImage="/demo/monkey.png"
            cardFooter={{
              text: 'Mint',
              action: () => navigate(`/${routes.nftHub}/${collectionId}/${routes.mintNFT}/${123}`),
              arrowFill: '#000',
            }}
            chains={['ethereum', 'polygon']}
            hideBackgroundImage
          />
        </div>
      ) : (
        <div className="mt-12">
          <AddCollectionCard
            cardTheme="light"
            onClick={() => navigate(`/${routes.nftHub}/${collectionId}/${routes.createNFT}`)}
            className="cursor-pointer w-auto max-w-[448px] min-w-[300px]"
            classNames={{
              subtext: 'md:!text-[24px] !text-[18px] !leading-none',
              button: 'md:w-12 w-10 md:h-12 h-10',
            }}
            title="ready to create"
            subtext="Create a New NFT"
            button={<CardPlus fill="#fff" />}
          />
        </div>
      )}
    </div>
  );
};

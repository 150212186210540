import { twMerge } from 'tailwind-merge';

export function classes(...classLists: (string | null | undefined | false)[]) {
  return twMerge(...classLists.map((classList) => (!classList ? null : classList)));
}

export const NavbarOptions = [
  {
    name: 'Key Features',
    href: '#key-features',
  },
  {
    name: 'Use Cases',
    href: '#use-cases',
  },
  {
    name: 'How it Works',
    href: '#how-it-works',
  },
];

export function truncate(value: string | undefined, sideLength = 6): string {
  return value
    ? value.length > sideLength * 2
      ? `${value.substring(0, sideLength)}...${value.substring(value.length - sideLength)}`
      : value
    : '';
}

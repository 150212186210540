import { Dispatch, SetStateAction } from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Breadcrumb, Button, Card } from 'components';
import { DownArrow, InfoIcon } from 'components/Icons';
import NoImageFound from 'components/NoImageFound/NoImageFound';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import { useMintNFTStep2 } from './useMintNFTStep2';

export const CreateNFTStep2 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const { onNext, urlImage } = useMintNFTStep2({ handleActiveStep });

  return (
    <div className="md:flex gap-10">
      <div className="relative md:w-1/2">
        <Breadcrumb items={breadcrumbData} />
        <div className="flex items-center justify-between pt-6">
          <h2 className="md:text-5xl text-[40px] leading-10 font-bold font-secondary uppercase text-lightBlack">
            Mint NFT
          </h2>
        </div>

        <div className="w-full divide-y divide-red/5 rounded-xl bg-[#F8F8F8] overflow-hidden mt-6">
          <Disclosure as="div" defaultOpen={false} className="">
            <DisclosureButton className="flex items-center justify-between w-full group px-6 py-5">
              <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                Fees
              </span>
              <DownArrow className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
            </DisclosureButton>
            <DisclosurePanel className="flex flex-col gap-2 text-sm/5 px-6 py-4 border-t border-[#E6E6E6]">
              <div className="flex justify-between md:text-lg text-base">
                <div className="text-black/50 flex items-center gap-1">
                  Gas Fee
                  <>
                    <InfoIcon className="cursor-pointer" data-tooltip-id="test">
                      "Gas Fee tooltip"
                    </InfoIcon>

                    <ReactTooltip
                      id="test"
                      place="bottom"
                      content="test"
                      className="!rounded-xl !bg-lightBlack !text-xs !font-medium"
                    />
                  </>
                </div>

                <div>-</div>
              </div>
              <div className="flex justify-between md:text-lg text-base">
                <div className="text-black/50">Network Fee</div>
                <div>-</div>
              </div>
            </DisclosurePanel>
          </Disclosure>
          <Disclosure as="div" className="px-6 py-4 bg-[#F2F2F2] !border-t-0">
            <div className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80 flex justify-between">
              <span className="text-xs">Total</span>
              <span>$40.00</span>
            </div>
          </Disclosure>
        </div>

        <div className="flex justify-between px-[1px] gap-4 !mt-[57px] bottom-0 md:absolute w-full fixed left-0 z-10 md:bg-transparent bg-white md:!p-0 !p-4 md:border-0 border-t border-lightBlack/20">
          <Button
            color="black"
            variant="outlined"
            className="justify-center w-full"
            onClick={() => handleActiveStep((activeStep) => activeStep - 1)}
          >
            BACK
          </Button>
          <Button
            color="black"
            variant="contained"
            className="justify-center w-full"
            onClick={onNext}
          >
            NEXT
          </Button>
        </div>
      </div>
      <div className="md:mb-0 mb-20 md:mt-0 mt-6 md:max-w-[50%]">
        <Card
          cardTheme="light"
          className="!p-0 overflow-hidden md:w-full md:h-[593px] w-full h-full border-none sm:mt-10 lg:mt-0"
          hideBackgroundImage
        >
          {urlImage && <img src={urlImage} className="object-cover w-full h-full" />}
          {!urlImage && <NoImageFound />}
        </Card>
      </div>
    </div>
  );
};

import { Props } from './types';

export function DownloadIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 947 852"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.441 165.664V331.327L331.809 331.212H213.492V354.895V378.577H237.064H260.636V402.26V425.943H284.322H308.008V449.625V473.308H331.695H355.381V496.99V520.673H379.068H402.754V544.355V568.038H426.441H450.127V591.721V615.403H473.814H497.5V591.721V568.038H521.186H544.873V544.355V520.673H568.559H592.246V496.99V473.308H615.932H639.619V449.625V425.943H663.305H686.992V402.26V378.577H710.679H734.367L734.308 354.952L734.25 331.327L639.561 331.269L521.186 331.327V165.663V0H473.814H426.441V165.664ZM0 615.289V804.635H23.6864H47.3729V828.317V852H473.5H899.627V828.317V804.635H923.314H947V615.289V425.943H899.627H852.254V591.606V757.27H473.614H94.9746V591.606V425.943H47.4873H0V615.289Z"
        fill="#fff"
      />
    </svg>
  );
}

import { Dispatch, SetStateAction } from 'react';

import { Breadcrumb, Button, Card } from 'components';
import FormRadioGroup from 'components/FormRadioGroup/FormRadioGroup';
import NoImageFound from 'components/NoImageFound/NoImageFound';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import { CHAIN_FIELD, STEP_TWO_FIELD } from '../../formFields';

import { useMintNFTStep1 } from './useMintNFTStep1';

const options = [
  { label: 'polygon', value: 'polygon' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'binance', value: 'binance' },
  { label: 'Astar', value: 'astar' },
  { label: 'Avalanche', value: 'avalanche' },
];

export const MintNFTStep1 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const { onNext, urlImage } = useMintNFTStep1({ handleActiveStep });

  return (
    <div className="md:flex gap-10 w-full">
      <div className="relative md:w-1/2">
        <Breadcrumb items={breadcrumbData} />
        <h2 className="md:text-5xl text-[40px] leading-10 font-bold font-secondary uppercase md:py-6 pt-6 pb-4 text-lightBlack">
          Mint NFT
        </h2>
        <div className="space-y-4">
          <FormRadioGroup
            name={`${STEP_TWO_FIELD}.${CHAIN_FIELD}`}
            className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-4"
            options={options}
          />
        </div>
        <div className="flex justify-between px-[1px] gap-4 !mt-[57px] bottom-0 md:absolute w-full fixed left-0 z-10 md:bg-transparent bg-white md:!p-0 !p-4 md:border-0 border-t border-lightBlack/20">
          <Button
            color="black"
            variant="contained"
            className="justify-center w-full"
            onClick={onNext}
          >
            NEXT
          </Button>
        </div>
      </div>
      <div className="md:mb-0 mb-20 md:mt-0 mt-6 md:max-w-[50%]">
        <Card
          cardTheme="light"
          className="!p-0 overflow-hidden md:w-full md:h-[593px] w-full h-full border-none sm:mt-10 lg:mt-0"
          hideBackgroundImage
        >
          {urlImage && <img src={urlImage} className="object-cover w-full h-full" />}
          {!urlImage && <NoImageFound />}
        </Card>
      </div>
    </div>
  );
};

import { Button, DataTable } from 'components';
import LinkArrow from 'components/Icons/LinkArrow';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { Header } from 'types';

const headers: Header[] = [
  {
    key: 'image',
    label: 'NAME',
    render: (image: string, row) => (
      <div className="flex items-center space-x-2">
        <img src={image} alt="NFT" className="w-8 h-8 rounded-full" />
        <span>{row.name}</span>
      </div>
    ),
  },
  { key: 'collection', label: 'COLLECTION' },
  {
    key: 'source',
    label: 'SOURCE',
    render: (source: string) => (
      <div className="flex items-center space-x-2">
        <img src={`/icons/chain/${source}.svg`} className="h-6 w-6" />
        <span>{source}</span>
      </div>
    ),
  },
  {
    key: 'destination',
    label: 'DESTINATION',
    render: (destination: string) => (
      <div className="flex items-center space-x-2">
        <img src={`/icons/chain/${destination}.svg`} className="h-6 w-6" />
        <span>{destination}</span>
      </div>
    ),
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (status: string) => (
      <div className="flex items-center space-x-2 ">
        <span className="text-[#4DB50E] bg-[#00D33B]/10 p-2 rounded-full text-xs">Completed</span>
      </div>
    ),
  },
  { key: 'finalized', label: 'FINALIZED', renderClassName: 'text-opacity-70' },
];

const dummyData = [
  {
    image: 'https://via.placeholder.com/40',
    name: 'NFT Name 1',
    collection: 'Collection Name 1',
    source: 'ethereum',
    destination: 'polygon',
    status: 'Completed',
    finalized: '20 minutes ago',
  },
  {
    image: 'https://via.placeholder.com/40',
    name: 'NFT Name 2',
    collection: 'Collection Name 2',
    source: 'bitcoin',
    destination: 'ethereum',
    status: 'Completed',
    finalized: '21 minutes ago',
  },
  {
    image: 'https://via.placeholder.com/40',
    name: 'NFT Name 3',
    collection: 'Collection Name 3',
    source: 'astar',
    destination: 'avalanche',
    status: 'Completed',
    finalized: '22 minutes ago',
  },
  {
    image: 'https://via.placeholder.com/40',
    name: 'NFT Name 4',
    collection: 'Collection Name 4',
    source: 'optimism',
    destination: 'polygon',
    status: 'Completed',
    finalized: '23 minutes ago',
  },
];

export const Bridge = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="justify-between items-end md:flex mb-7 my-5 md:my-8">
        <div>
          <div className="md:text-5xl text-[32px] md:leading-10 leading-8 font-bold font-secondary uppercase md:mb-0 mb-1">
            NFT Tracker
          </div>
          <div className="text-textGray">Teleport your NFTs to a new chain.</div>
        </div>
        <div className="flex gap-4">
          <Button
            variant="contained"
            color="black"
            onClick={() => navigate(`/${routes.bridge}/${routes.create}`)}
            endIcon={
              <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-white">
                <LinkArrow className="w-6 h-6 text-black" fill="black" />
              </div>
            }
            className="!pr-1 md:!pl-6 !pl-4 md:!gap-5 md:!py-7 justify-between md:mt-6 mt-4 w-full"
          >
            Bridge NFT
          </Button>
        </div>
      </div>
      <DataTable
        headers={headers}
        data={dummyData}
        pageSize={4}
        totalCount={1000}
        onPageChange={(page) => console.log('Page Changed:', page)}
      />
    </>
  );
};

export default Bridge;

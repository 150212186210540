import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Form from 'components/Form';
import { routes } from 'routes/routesConst';

import { CreateBridgeNFTStep1 } from './components/CreateBridgeNFTStep1/CreateBridgeNFTStep1';
import { CreateBridgeNFTStep2 } from './components/CreateBridgeNFTStep2/CreateBridgeNFTStep2';
import { BridgeNFTSchema } from './CreateBridgeNFTSchema';

export const CreateBridgeNFT = () => {
  const [activeStep, handleActiveStep] = useState(1);

  const breadcrumbData = [
    {
      path: `/${routes.bridge}`,
      label: 'Bridge',
    },
    {
      path: `/${routes.bridge}/${routes.create}`,
      label: 'BridgeNFT',
    },
  ];

  const breadcrumbDataStep2 = [
    {
      path: `/${routes.bridge}`,
      label: 'Bridge',
    },
    {
      path: `/${routes.bridge}/${routes.create}`,
      label: 'Bridge NFT',
    },
    {
      path: `/${routes.bridge}/${routes.create}/${routes.selectCollection}`,
      label: 'Select Collection',
    },
  ];

  return (
    <Form
      className="!pb-0 mx-auto"
      resolver={zodResolver(BridgeNFTSchema)}
      action="/hello"
      mode="onChange"
    >
      {activeStep === 1 && (
        <CreateBridgeNFTStep1 handleActiveStep={handleActiveStep} breadcrumbData={breadcrumbData} />
      )}
      {activeStep === 2 && (
        <CreateBridgeNFTStep2
          handleActiveStep={handleActiveStep}
          breadcrumbData={breadcrumbDataStep2}
        />
      )}
    </Form>
  );
};

export default CreateBridgeNFT;

import { Props } from './types';

export const DoubleRightArrow = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#000"
      d="M12.303 12.899 4.94 19.925a1.338 1.338 0 0 1-1.835 0L1.883 18.76a1.196 1.196 0 0 1 0-1.752L7.1 12.026 1.883 7.044a1.195 1.195 0 0 1 0-1.751L3.1 4.114a1.338 1.338 0 0 1 1.835 0l7.362 7.028c.514.486.514 1.271.006 1.757Zm10.394-1.757-7.362-7.028a1.338 1.338 0 0 0-1.836 0l-1.223 1.168a1.194 1.194 0 0 0 0 1.751l5.219 4.982-5.219 4.982a1.194 1.194 0 0 0 0 1.752l1.223 1.167a1.338 1.338 0 0 0 1.836 0l7.362-7.027a1.188 1.188 0 0 0 0-1.748v.001Z"
    />
  </svg>
);

export default DoubleRightArrow;

import { Dispatch, SetStateAction } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormRadioGroup, Modal } from 'components';
import Form from 'components/Form';
import { HTMLAttributes } from 'types';
import { z } from 'zod';

const options = [
  { label: 'Polygon', value: 'Polygon' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Binance', value: 'Binance' },
  { label: 'Astar', value: 'astar' },
  { label: 'Avalanche', value: 'avalanche' },
];

const networkSelectionSchema = z.object({
  chain: z
    .string({ required_error: 'Please select relevant network' })
    .min(1, 'Please select relevant network'),
});

interface DeployCollectionModelProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
  setDeployModalOpen: Dispatch<SetStateAction<boolean>>;
  setCongratulationsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const DeployCollectionModel = ({
  isOpen,
  className,
  setIsOpen,
  isCloseHidden,
  setDeployModalOpen,
  setCongratulationsModalOpen,
}: DeployCollectionModelProps) => {
  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{ base: 'bg-white text-black' }}
      isCloseHidden={isCloseHidden}
    >
      <Form resolver={zodResolver(networkSelectionSchema)} action="/hello">
        <div className="uppercase md:text-[40px] text-[32px] leading-10 md:leading-8 font-secondary">
          Deploy Your Collection
        </div>
        <div className="text-sm md:text-base text-textGray md:mt-4">
          Select the blockchains where your collection will reside.
        </div>

        <FormRadioGroup
          name="chain"
          className="grid grid-cols-2 md:grid-cols-3 md:gap-4 gap-3 md:mt-8 mt-6"
          options={options}
          classNames={{ radio: 'p-2 border-lightBlack/[0.15]' }}
        />
        <div className="flex flex-row gap-4 pt-6 md:pt-8 ">
          <Button
            color="black"
            variant="outlined"
            className="md:w-[320px] w-[147px] justify-center"
            onClick={() => setIsOpen(false)}
          >
            BACK
          </Button>
          <Button
            color="black"
            className="md:w-[320px] w-[147px] justify-center"
            onClick={() => {
              // TODO: Need to do this form success on API integration
              setDeployModalOpen(false);
              setCongratulationsModalOpen(true);
            }}
          >
            Deploy
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

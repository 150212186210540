import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';

export function SuccessMessage() {
  const navigate = useNavigate();
  const images = Array.from(
    { length: 10 },
    (_, i) => `/FractionalizeImages/Fractionalize_${i + 1}.svg`
  );

  return (
    <>
      <div className="md:w-[640px] mx-auto pt-[57px]">
        <div className="grid grid-cols-5 gap-0.5 rounded-2xl bg-white overflow-hidden">
          {images.map((src, index) => (
            <img key={index} src={src} alt={`Fractionalize ${index + 1}`} />
          ))}
        </div>

        <h2 className="md:text-5xl text-[40px] md:leading-10 leading-[normal] font-bold font-secondary uppercase mt-8 mb-2">
          Collection successfully created.
        </h2>
        <div className="text-sm md:text-base text-textGray">
          Your new collection is ready to be deployed! Deploy now or create NFTs.
        </div>

        <div className="flex md:flex-row flex-col justify-between px-[1px] md:gap-4 gap-2 md:!mt-8 !mt-4">
          <Button
            variant="outlined"
            color="black"
            className="justify-center w-full decoration-1"
            onClick={() => navigate(`/${routes.nftHub}/${123}/${routes.createNFT}`)}
          >
            Create NFT
          </Button>
          <Button type="submit" color="black" variant="contained" className="justify-center w-full">
            Deploy Collection
          </Button>
        </div>
      </div>
    </>
  );
}

export default SuccessMessage;

export function Tick() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48242 4.85102L4.86138 4.85102L4.86138 3.22998L6.48242 3.22998L6.48242 4.85102ZM3.24034 3.22998L1.6193 3.22998L1.6193 4.85102L3.24034 4.85102L3.24034 6.47206L4.86138 6.47206L4.86138 4.85102L3.24034 4.85102L3.24034 3.22998Z"
        fill="white"
      />
      <path
        d="M8.10332 1.60909L6.48228 1.60909L6.48228 3.23013L8.10332 3.23013L8.10332 1.60909Z"
        fill="white"
      />
      <path
        d="M9.72664 1.19686e-09L8.1056 -7.02322e-08L8.1056 1.62104L9.72664 1.62104L9.72664 1.19686e-09Z"
        fill="white"
      />
      <path
        d="M-0.000250697 1.60889L-0.000250769 3.22993L1.62109 3.23013L1.62099 1.60889L-0.000250697 1.60889Z"
        fill="white"
      />
    </svg>
  );
}

export default Tick;

import { DropzoneOptions } from 'react-dropzone';
import { Controller, RegisterOptions } from 'react-hook-form';

import Dropzone from '../Dropzone';

export interface FormDropzoneProps extends DropzoneOptions {
  name: string;
  children?: React.ReactNode;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  control?: any;
  readOnly?: boolean;
  isAddMode: boolean;
  isRender?: boolean;
  className?: string;
}

const FormDropzone: React.FC<FormDropzoneProps> = ({
  name,
  multiple,
  children,
  rules,
  control,
  readOnly,
  isAddMode,
  isRender,
  ...rest
}) => (
  <Controller
    render={({ field: { onChange: onFieldChange, value }, fieldState: { error } }) => {
      return (
        <>
          <Dropzone
            error={error}
            onDrop={(e: any) => {
              onFieldChange(e);
            }}
            isAddMode={isAddMode}
            value={value}
            children={children}
            readOnly={readOnly}
            isRender={isRender}
            multiple={multiple}
            {...rest}
          />
          {error && (
            <span className="text-sm text-error flex gap-1 mt-2 overflow-visible z-10 relative">
              <img src="/closeCircle.svg" alt="bodyYellowBg" />
              {error.message}
            </span>
          )}
        </>
      );
    }}
    rules={rules}
    name={name}
    control={control}
  />
);

export default FormDropzone;

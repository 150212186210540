import { Radio, RadioGroup } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { ClassNames } from 'types';
import { classes } from 'utils';

type Option = {
  label: string;
  value: string;
};

type FormRadioGroupProps = {
  name: string;
  options: Option[];
  shouldUnregister?: boolean;
  tooltip?: string;
  className?: string;
  classNames?: ClassNames<'radio'>;
};

export const FormRadioGroup = ({
  name,
  options,
  shouldUnregister,
  className,
  classNames,
  ...rest
}: FormRadioGroupProps) => {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => {
        return (
          <div className="w-full">
            <div className="w-full">
              <RadioGroup
                aria-label="Server size"
                className={classes('w-full', className)}
                onChange={(value) => onChange(value.value)}
                value={value}
                {...rest}
                {...restField}
              >
                {options.map((option) => (
                  <Radio
                    key={option.label}
                    value={option}
                    className={classes(
                      'group !bg-white relative flex items-center gap-2 cursor-pointer rounded-full border border-lightBlack bg-white/5 md:p-4 p-3 data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:border-lightBlack',
                      classNames?.radio
                    )}
                  >
                    {option?.label && (
                      <div className="flex-shrink-0 overflow-hidden rounded-full">
                        <img
                          src={`/icons/chain/${option.label.toLowerCase()}.svg`}
                          height={32}
                          width={32}
                          className="block object-cover"
                        />
                      </div>
                    )}
                    <div className="flex items-center justify-between w-full">
                      <p className="md:text-base text-sm font-medium">{option?.label}</p>
                      <div className="flex items-center gap-[7px] justify-between">
                        {value?.toLowerCase() === option?.label?.toLowerCase() ? (
                          <div className="rounded-full border-[1px] border-lightBlack">
                            <div className="p-[7px] bg-lightBlack m-1 rounded-full"></div>
                          </div>
                        ) : (
                          <div className="rounded-full border-[12px] border-lightBlack/[0.05]">
                            <div className="p-0"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Radio>
                ))}
              </RadioGroup>
              {error && (
                <span className="text-sm text-error flex gap-1 mt-2">
                  <img src="/closeCircle.svg" alt="bodyYellowBg" />
                  {error.message}
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default FormRadioGroup;

import { useMemo, useRef } from 'react';
import './pixelport.css';

import { useGSAP } from '@gsap/react';
import { Footer, Navbar } from 'components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from 'hooks';

import { HeroSection } from './HeroSection';
import { HowItWorks } from './HowItWorks';
import { KeyFeatures } from './KeyFeatures';
import { UseCases } from './UseCases';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const LEFT_FACTORY_IMAGE = '/newDesign/leftFactory.webp';
const PLANE_IMAGE = '/newDesign/plane.webp';
const RIGHT_FACTORY = '/newDesign/rightFactory.webp';
const WHITE_CLOUDS_IMAGE = '/newDesign/whiteClouds.webp';
const PILOT_IMAGE = '/newDesign/pilot.png';
const MOUNTAIN_CITY_IMAGE = '/newDesign/mountainCity.webp';
const MULTI_PLANE_IMAGE = '/newDesign/multiPlane.webp';

// List of image URLs you want to preload

export const Landing = () => {
  const plane = useRef<HTMLDivElement | null>(null);
  const cloud = useRef<HTMLDivElement | null>(null);
  const multiPlaneRef = useRef<HTMLDivElement | null>(null);
  const pilotRef = useRef<HTMLDivElement | null>(null);

  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = useMemo(() => width < 768, [width]);

  useGSAP(
    () => {
      const planes = gsap.utils.toArray('.plane') as HTMLElement[];
      planes.forEach((plane) => {
        gsap.to(plane, {
          x: isBelowMd ? 100 : 350,
          scrollTrigger: {
            trigger: plane,
            start: 'top top',
            end: 'bottom 1%',
            scrub: 0.7,
          },
        });
      });
    },
    { scope: plane }
  );

  useGSAP(
    () => {
      const clouds = gsap.utils.toArray('.whiteCloud') as HTMLElement[];
      clouds.forEach((cloud) => {
        gsap.to(cloud, {
          y: -100,
          scrollTrigger: {
            trigger: cloud,
            start: 'top 85%',
            end: 'top 10%',
            scrub: 0.7,
          },
        });
      });
    },
    { scope: cloud }
  );
  useGSAP(
    () => {
      const multiPlanes = gsap.utils.toArray('.multiPlanes') as HTMLElement[];
      multiPlanes.forEach((multiPlane) => {
        gsap.to(multiPlane, {
          x: isBelowMd ? 100 : 500,
          scrollTrigger: {
            trigger: multiPlane,
            start: 'top 100%',
            end: 'bottom 1%',
            scrub: 0.7,
          },
        });
      });
    },
    { scope: multiPlaneRef, dependencies: [isBelowMd], revertOnUpdate: true }
  );

  useGSAP(
    () => {
      const pilots = gsap.utils.toArray('.pilot') as HTMLElement[];
      pilots.forEach((pilot) => {
        gsap.to(pilot, {
          x: 400,
          scrollTrigger: {
            trigger: pilot,
            start: 'top 100%',
            end: 'bottom 1%',
            scrub: 0.7,
          },
        });
      });
    },
    { scope: pilotRef }
  );

  return (
    <>
      <div className={`w-full mx-auto`} ref={cloud}>
        <Navbar />
        <div ref={plane}>
          <img
            src={LEFT_FACTORY_IMAGE}
            alt="left-factory"
            className="absolute -top-0 -left-30 hidden lg:flex xs:h-[700px] sm:h-[600px] md:h-[600px] lg:h-[550px] xl:h-[600px] 2xl:h-auto"
            height={500}
            width={500}
          />
          <div className="plane">
            <img
              src={PLANE_IMAGE}
              alt="plane"
              className="z-10 w-[157px] md:w-[270px] 2xl:w-[339px] relative top-[58px] ml-[9%] md:ml-[29%] left-auto"
              height={800}
              width={800}
            />
          </div>
          <img
            src={RIGHT_FACTORY}
            alt="right-factory"
            className="absolute -right-[70px] md:right-[-240px] lg:right-[-170px] 2xl:right-[-100px] top-[410px] md:top-[70px] 2xl:top-[121px] h-[280px] md:h-[866px] 2xl:h-[766px]"
            height={500}
          />
        </div>
        <HeroSection />
        <div className="relative md:mt-[-60px]">
          <img
            src={WHITE_CLOUDS_IMAGE}
            alt="white-clouds"
            className="w-full mx-auto relative -top-[20px] lg:top-[-250px]"
            height={'100'}
            width={'100'}
          />
          <img
            src={WHITE_CLOUDS_IMAGE}
            alt="white-clouds"
            className="whiteCloud w-full absolute top-[60px] lg:top-[-150px]"
            height={'100'}
            width={'100'}
          />
        </div>
        <div
          className="relative z-[5]"
          style={{
            background: `url(${MOUNTAIN_CITY_IMAGE})`,
            backgroundPosition: 'bottom',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="flex justify-start mx-auto max-w-[1400px] mt-[-27%]" ref={multiPlaneRef}>
            <KeyFeatures />
            <div className="multiPlanes absolute top-44">
              <img
                src={MULTI_PLANE_IMAGE}
                alt="multiPlane"
                className="h-[220px] mt-[140px] md:mt-auto ml-auto md:ml-[300px]"
                loading="lazy"
              />
            </div>
          </div>

          <div ref={pilotRef}>
            <div className="pilot absolute z-[6] top-[30%] md:top-[30%] 2xl:top-[28%] left-[330px]">
              <img src={PILOT_IMAGE} alt="pilot" className="h-[150px]" loading="lazy" />
            </div>
            <div className="pilot absolute z-[6] top-[32%] md:top-[32%] 2xl:top-[19%] -left-[500px]">
              <img src={PILOT_IMAGE} alt="pilot" className="h-[350px]" loading="lazy" />
            </div>
          </div>
        </div>

        <div
          className="mt-[-200px] md:mt-[-350px] 2xl:mt-[-450px] pt-[4rem]  md:pt-[16rem] lg:pt-[34rem] 2xl:pt-[30rem] z-[4] relative !bg-no-repeat bg-100-auto !bg-black"
          style={{
            background: !isBelowMd
              ? 'url(/newDesign/cave.webp) 0px 0px / 100% auto'
              : 'url(/newDesign/cave_mobile.webp)  0px 0px / 100% auto',
            backgroundSize: 'contain',
            backgroundColor: 'black',
          }}
        >
          <div className="multiPlanes absolute top-[160px]  xl:top-[260px] 2xl:top-[320px]">
            <img
              src={'/newDesign/left_men.png'}
              alt="multiPlane"
              className="h-[400px] lg:h-[500px] 2xl:h-full md:mt-auto ml-auto md:ml-[6px]"
              loading="lazy"
            />
          </div>
          <div className="multiPlanes absolute top-[160px] xl:top-[260px] 2xl:top-[300px] right-[10px]">
            <img
              src={'/newDesign/right_men.png'}
              alt="multiPlane"
              className="h-[400px] md:h-[600px] lg:h-[800px] 2xl:h-full md:mt-auto ml-auto md:ml-[6px]"
              loading="lazy"
            />
          </div>
          <UseCases />

          <div className="flex container mx-auto max-w-[1400px] relative pb-[300px]">
            <HowItWorks />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

import { Button } from 'components';
import { SDKICon } from 'components/Icons';
import { DownloadIcon } from 'components/Icons/DownloadIcon';

import { DownloadCard, MyCollections } from './components';

export const Collections = () => {
  return (
    <div className="flex flex-col">
      <div className="pt-8 pb-6 md:pt-10 md:pb-8">
        <div className="font-['Arcade'] md:text-[56px] text-5xl uppercase leading-none">
          Welcome Back, John
        </div>
        <div className="text-sm md:text-base text-textGray">Your NFT playground at a glance.</div>
      </div>
      <div className="flex gap-4 flex-col xl:flex-row">
        <DownloadCard
          icon={<SDKICon className="md:w-[30px] md:h-[30px] w-6 h-6" />}
          content={<span className="font-semibold">Download Pixelport SDK</span>}
          subtext="Add bridge and fractionalize features to your app "
          endButton={
            <Button
              variant="contained"
              color="white"
              startIcon={
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-lightBlack">
                  <DownloadIcon />
                </div>
              }
              className="gap-2 md:gap-5 md:w-auto w-full justify-start !max-h-12 pl-1"
            >
              download
            </Button>
          }
        />
        {/* <DownloadCard
          icon={<Maximize className="md:w-[30px] md:h-[30px] w-6 h-6" />}
          content={<span>NFT HUB</span>}
          subtext="Track all your NFTs across all chains in one place."
          endButton={
            <Button
              variant="contained"
              color="white"
              startIcon={
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-lightBlack">
                  <Maximize />
                </div>
              }
              className="gap-2 md:gap-5 md:w-auto w-full justify-start !max-h-12 pl-1"
            >
              VIEW
            </Button>
          }
        /> */}
      </div>
      <MyCollections />
    </div>
  );
};

export default Collections;

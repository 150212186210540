import React, { useEffect, useMemo, useState } from 'react';

import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FieldError, useFormContext } from 'react-hook-form';

export interface DropzoneProps extends DropzoneOptions {
  children?: React.ReactNode;
  value: any;
  error?: FieldError;
  readOnly?: boolean;
  isAddMode?: boolean;
  isRender?: boolean;
}

const Dropzone: React.FC<DropzoneProps> = ({
  multiple,
  onDrop: onDropControllerFun,
  children,
  value,
  error,
  readOnly,
  isAddMode,
  isRender,
  ...rest
}) => {
  const [files, setFiles] = useState<any>(() => (value ? [value] : []));
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    onDrop: (files, rejectedFiles, event) => {
      setFiles(() => files.map((file) => URL.createObjectURL(file)));
      onDropControllerFun && onDropControllerFun(files, rejectedFiles, event);
    },
    ...rest,
  });

  const formContext = useFormContext();

  useEffect(() => {
    if (value) setFiles([value]);
  }, [formContext.control._formValues]);

  const renderImages = useMemo(
    () =>
      files.map((fileURL: any, index: number) => (
        <div key={index} className="h-full w-full">
          <img
            className="w-full h-full object-cover mx-auto rounded-2xl"
            alt="not Found"
            src={typeof fileURL === 'object' ? URL.createObjectURL(fileURL[0]) : fileURL}
            onError={() => {
              setFiles([]);
            }}
          />
        </div>
      )),
    [files]
  );

  const container = useMemo(() => {
    if (isAddMode || readOnly) {
      if (files.length && isRender && value && !error) {
        return renderImages;
      } else {
        return children;
      }
    } else {
      return (
        <>
          {children}
          {renderImages}
        </>
      );
    }
  }, [isAddMode, readOnly, files, isRender, value, error, children, renderImages]);

  return (
    <div className="h-full w-full">
      <div
        className="h-full w-full flex justify-center items-center"
        {...getRootProps()}
        data-testid="dropzone"
      >
        <input {...getInputProps()} />
        {container}
      </div>
    </div>
  );
};

export default Dropzone;

import { ReactNode } from 'react';

import { Card, CardProps } from 'components';
import LinkArrow from 'components/Icons/LinkArrow';
import { Link } from 'react-router-dom';
import { ClassNames } from 'types';
import { classes } from 'utils';

interface ExistingCollectionCard {
  cardTheme?: 'light' | 'dark';
  button?: ReactNode;
  title: string;
  collectionName: string;
  mintedDate?: string;
  items?: string;
  tokenType?: string;
  classNames?: ClassNames<
    | 'base'
    | 'title'
    | 'link'
    | 'dot'
    | 'bgImage'
    | 'heading'
    | 'date'
    | 'headingContainer'
    | 'linkAction'
  >;
  className?: string;
  backgroundImage?: string;
  featureImage?: string;
  cardFooter?: {
    text: string;
    action?: () => void;
    arrowFill?: string;
  };
  cardProps?: CardProps;
  chains?: string[];
  hideBackgroundImage?: boolean;
}

export const ExistingCollectionCard = ({
  cardTheme = 'dark',
  title,
  collectionName,
  mintedDate,
  items,
  tokenType,
  classNames,
  backgroundImage,
  featureImage,
  cardFooter,
  cardProps,
  chains,
  hideBackgroundImage,
}: ExistingCollectionCard) => {
  return (
    <Card
      cardTheme={cardTheme}
      className={classNames?.base}
      backgroundImage={backgroundImage}
      featureImage={featureImage}
      hideBackgroundImage={hideBackgroundImage}
      {...cardProps}
    >
      <div
        className={classes(`px-5 pb-[15px] bg-[url('${backgroundImage}')]`, classNames?.bgImage)}
        style={{ background: `url(${backgroundImage})` }}
      >
        <div className="flex items-center gap-2 pt-[14px] pb-3">
          {chains && chains.length && (
            <div className="flex -space-x-1">
              {chains.map((chain, index) => (
                <img
                  key={index}
                  className={classes(
                    'inline-block h-5 w-5 rounded-full  ring-neutral-900',
                    index > 0 && 'ring-2'
                  )}
                  src={`/icons/chain/${chain.toLowerCase()}.svg`}
                  alt={chain}
                />
              ))}
            </div>
          )}
          <div className={classes('uppercase text-xs leading-6 text-white', classNames?.title)}>
            {title}
          </div>
          <Link
            to="#"
            className={classes('font-bold leading-none text-white ml-auto', classNames?.dot)}
          >
            ...
          </Link>
        </div>
        <div
          className={classes(
            'flex justify-between items-center text-white font-secondary',
            classNames?.headingContainer
          )}
        >
          <div
            className={classes(
              'text-white font-secondary leading-8 md:text-[32px] text-2xl uppercase',
              classNames?.heading
            )}
          >
            {collectionName}
          </div>
          {mintedDate && (
            <span
              className={classes(
                'whitespace-nowrap opacity-70 text-xs font-primary',
                classNames?.date
              )}
            >
              {mintedDate}
            </span>
          )}
        </div>

        <div className="flex justify-between text-xs text-white text-opacity-50">
          {items && <div className="font-normal">{items} items</div>}
          {tokenType && <div>{tokenType}</div>}
        </div>
      </div>
      {cardFooter && (
        <div
          className={classes(
            'uppercase px-5 py-[17px] rounded-lg bg-[#232323] mb-2.5 mx-2 font-Jakarta text-xl leading-[normal]',
            classNames?.link
          )}
        >
          <a
            className={classes(
              'flex items-center justify-center text-white gap-1',
              !cardFooter.action ? 'text-white ' : 'cursor-pointer',
              classNames?.linkAction
            )}
            onClick={cardFooter.action && cardFooter.action}
          >
            {cardFooter.text}
            {cardFooter.action && (
              <LinkArrow className="w-[20px] h-[20px]" fill={cardFooter?.arrowFill || '#fff'} />
            )}
          </a>
        </div>
      )}
    </Card>
  );
};

export default ExistingCollectionCard;

import { Dispatch, SetStateAction } from 'react';

import { Button, FormDropdown, FormTextInput } from 'components';
import { InfoIcon } from 'components/Icons';

import {
  COLLECTION_NAME_FIELD,
  COLLECTION_SYMBOL_FIELD,
  STEP_TWO_FIELD,
  TOKEN_TYPE_FIELD,
} from '../../formFields';
import { TOKEN_TYPES } from '../../WizardSchema';

import { useNewCollectionFormStep1 } from './useNewCollectionFormStep1';

export const NewCollectionFormStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { onNext } = useNewCollectionFormStep1({ handleActiveStep });

  return (
    <>
      <div className="flex justify-center flex-col h-full mb-[48px] md:mb-0 md:w-[640px] md:translate-y-[-84px]">
        <h2 className="md:text-5xl text-[40px] md:leading-10 leading-[normal] font-bold font-secondary text-lightBlack">
          COLLECTION DETAILS
        </h2>
        <div className="flex flex-col gap-5 mt-6">
          <FormTextInput
            name={`${STEP_TWO_FIELD}.${COLLECTION_NAME_FIELD}`}
            shouldUnregister={false}
            placeholder="e.g. Pixelport Collection"
            label="Collection Name"
          />
          <FormTextInput
            name={`${STEP_TWO_FIELD}.${COLLECTION_SYMBOL_FIELD}`}
            shouldUnregister={false}
            placeholder="e.g. HLGRH"
            label="Collection Symbol"
            labelIcon={<InfoIcon />}
            tooltip="Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
          />
          <FormDropdown
            name={`${STEP_TWO_FIELD}.${TOKEN_TYPE_FIELD}`}
            shouldUnregister={false}
            options={TOKEN_TYPES as unknown as string[]}
            label="Token Type"
          />
        </div>
        <div className="flex justify-between px-[1px] gap-4 md:!mt-8 md:relative fixed bottom-0 left-0 z-10 w-full md:!p-0 !p-4">
          <Button
            onClick={() => handleActiveStep((step: number) => step - 1)}
            variant="outlined"
            color="black"
            className="justify-center w-full"
          >
            BACK
          </Button>
          <Button
            color="black"
            variant="contained"
            className="justify-center w-full"
            onClick={onNext}
          >
            NEXT
          </Button>
        </div>
      </div>
    </>
  );
};

import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';

import { DESCRIPTION_FIELD, STEP_ONE_FIELD, UPLOAD_NFT_FIELD } from '../../formFields';
import { CreateNFTFormType } from '../../WizardSchemaForCreateNFT';

export const useCreateNFTStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { trigger, watch } = useFormContext<CreateNFTFormType>();

  const nftUpload = watch(`${STEP_ONE_FIELD}.${UPLOAD_NFT_FIELD}`);
  const description = watch(`${STEP_ONE_FIELD}.${DESCRIPTION_FIELD}`);

  const onNext = async () => {
    const isValid = await trigger(STEP_ONE_FIELD);

    if (isValid) {
      handleActiveStep((step) => step + 1);
    }
  };

  return {
    onNext,
    nftUpload,
    description,
  };
};

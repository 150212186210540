// StepOne
export const STEP_ONE_FIELD = 'stepOne';
export const CREATOR_NAME_FIELD = 'creatorName';
export const NFT_NAME_FIELD = 'nftName';
export const DESCRIPTION_FIELD = 'description';
export const UPLOAD_NFT_FIELD = 'upload_nft';

// StepTwo
export const STEP_TWO_FIELD = 'stepTwo';
export const CHAIN_FIELD = 'chain';

export const routes = {
  base: '/',
  connect: 'connect',
  nftHub: 'nftHub',
  bridge: 'bridge',
  fractionalize: 'fractionalize',
  create: 'create',
  collectionId: ':collectionId',
  createNFT: 'createNFT',
  mintNFT: 'mintNFT',
  nftId: ':nftId',
  selectCollection: 'selectCollection',
  404: '404',
};

export const combineRoutes = {
  createCollection: `/${routes.nftHub}/${routes.create}`,
  collectionDetail: `/${routes.nftHub}/${routes.collectionId}`,
  createNFT: `/${routes.nftHub}/${routes.collectionId}/${routes.createNFT}`,
  mintNFT: `/${routes.nftHub}/${routes.collectionId}/${routes.mintNFT}/${routes.nftId}`,
  createBridge: `/${routes.bridge}/${routes.create}`,
};

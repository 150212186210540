import { ReactNode } from 'react';

import { Card, CardProps } from 'components';
import { ClassNames } from 'types';
import { classes } from 'utils';

interface CollectionCardProps {
  cardTheme?: 'light' | 'dark';
  onClick?: () => void;
  button?: ReactNode;
  title?: string;
  subtext: string;
  classNames?: ClassNames<'base' | 'title' | 'subtext' | 'button'>;
  className?: string;
  backgroundImage?: string;
  cardProps?: CardProps;
  hideBackgroundImage?: boolean;
}

export const AddCollectionCard = ({
  cardTheme,
  onClick,
  title,
  subtext,
  button,
  className,
  classNames,
  backgroundImage,
  cardProps,
  hideBackgroundImage,
}: CollectionCardProps) => {
  return (
    <Card
      onClick={onClick}
      className={className}
      cardTheme={cardTheme}
      backgroundImage={backgroundImage}
      hideBackgroundImage={hideBackgroundImage}
      {...cardProps}
    >
      <div className={classes('flex justify-between md:pb-4 pb-3', classNames?.base)}>
        {title && (
          <div
            className={classes(
              'uppercase text-xs',
              cardTheme === 'dark' ? 'text-white' : 'text-lightBlack',
              classNames?.title
            )}
          >
            {title}
          </div>
        )}

        <div
          className={classes(
            'flex items-center justify-center rounded-full md:w-14 w-12 md:h-14 h-12',
            cardTheme === 'dark' ? 'bg-white/10 text-white' : 'bg-lightBlack text-white',
            classNames?.button
          )}
        >
          {button}
        </div>
      </div>

      <div
        className={classes(
          'uppercase text-[32px] leading-none',
          cardTheme === 'dark' ? 'text-white' : 'text-lightBlack',
          classNames?.subtext
        )}
      >
        {subtext}
      </div>
    </Card>
  );
};

export default AddCollectionCard;

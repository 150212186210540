import { FormProvider } from 'react-hook-form';
import { classes } from 'utils';

import { FormProps } from './types';
import useForm from './useForm';

/**
 * A component that provides a form with React Hook Form integration.
 *
 * @param {FormProps} props - The component's props.
 */
const Form: React.FC<FormProps> = ({
  children,
  form,
  onSubmit,
  onError,
  className,
  action,
  style,
  method = 'POST',
  axiosHeaders,
  ...rest
}) => {
  const { newForm, onResetHandler, onFormSubmitHandler, isLoading } = useForm({
    form,
    onSubmit,
    onError,
    action,
    method,
    axiosHeaders,
    ...rest,
  });

  return (
    <FormProvider {...newForm}>
      <form
        onSubmit={onFormSubmitHandler}
        onReset={onResetHandler}
        className={classes(className)}
        style={style}
      >
        {typeof children === 'function' ? children({ ...newForm, isLoading: isLoading }) : children}
      </form>
    </FormProvider>
  );
};

export default Form;

import { useMemo, useState } from 'react';

import Dropdown from 'components/Dropdown/Dropdown';
import { RightArrowIcon } from 'components/Icons';
import { ClassNames } from 'types';
import { classes } from 'utils';

export type PaginationProps = {
  defaultPage?: number;
  pageSize: number;
  totalCount: number;
  onPageChange?: (page: number) => void;
  classNames?: ClassNames<'container'>;
};

export function Pagination({
  defaultPage,
  pageSize,
  totalCount,
  onPageChange,
  classNames,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(defaultPage || 1);

  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (page: number | string) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
      if (onPageChange) onPageChange(page);
    }
  };

  const paginationArray = useMemo(() => {
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 4) {
      return [1, 2, 3, 4, '...', totalPages];
    }

    if (currentPage >= totalPages - 4) {
      return [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }, [currentPage, pageSize, totalCount]);

  return (
    <div
      className={classes(
        'flex justify-between sm:items-center  bg-transparent flex-col sm:flex-row gap-2',
        classNames?.container
      )}
    >
      <div className="flex sm:space-x-3 space-x-2 justify-between">
        <button disabled={currentPage <= 1} onClick={() => handlePageChange(currentPage - 1)}>
          <div className="flex items-center justify-center sm:w-10 w-9 sm:h-10 h-9 rounded-full border border-neutral-900 rotate-180">
            <RightArrowIcon />
          </div>
        </button>
        <div className="flex w-full justify-around">
          {paginationArray.map((page, index) => (
            <button
              key={`${page}${index}`}
              className={classes(
                'sm:px-3 px-[7px] text-sm font-medium text-neutral-900',
                page === currentPage &&
                  'px-3 sm:h-full h-9 rounded-full border border-neutral-900 border-opacity-20',
                page === currentPage && page === 1 && 'sm:px-4 px-3'
              )}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
        </div>
        <button
          disabled={currentPage >= totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <div className="flex items-center justify-center sm:w-10 w-9 sm:h-10 h-9 rounded-full border border-neutral-900">
            <RightArrowIcon />
          </div>
        </button>
      </div>
      <div className="flex items-center gap-3">
        <span className="text-sm text-neutral-900 font-normal">Items Per Page</span>
        <div className="border border-neutral-900 border-opacity-[15%] !mt-0 bg-white rounded-full">
          <Dropdown
            options={['04', '08', '12']}
            defaultSelected={'04'}
            classNames={{
              selector: 'p-0 h-auto justify-between w-full text-base',
              placeHolder: 'py-2.5 pl-4 pr-2',
              handlerIcon: 'h-full w-full p-3 rounded-full bg-[#f8f8f8]',
              base: 'w-full',
              container:
                'border-[#17171726] md:rounded-3xl md:bottom-14 rounded-tr-2xl rounded-tl-2xl',
              option: 'text-light-Black first:pt-0 pb-4 last:pb-0',
            }}
            mobileLabel="Select page"
          />
        </div>
      </div>
    </div>
  );
}

import ExistingCollectionCard from 'module/Collections/components/MyCollections/components/ExistingCollectionCard';

import { Dispatch, SetStateAction, useState } from 'react';

import { Field, Radio, RadioGroup } from '@headlessui/react';
import { Button, Modal } from 'components';
import { Tick } from 'components/Icons';
import { Controller } from 'react-hook-form';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

import { NFT_FIELD, STEP_TWO_FIELD } from '../../formFields';

export interface AddCollectionModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
  handleSaveClick?: () => void;
}

const NFTCollection = [
  {
    id: '1',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '2',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '3',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '4',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '5',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '6',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
  {
    id: '7',
    name: 'NFT NAME',
    mintedDate: '24 JUN  2024',
    network: 'Network Name',
    featureImage: '/demo/bald_style.png',
    chains: ['ethereum', 'polygon'],
  },
];

export const SelectNFTModal = ({
  isOpen,
  className,
  setIsOpen,
  isCloseHidden,
  handleSaveClick,
}: AddCollectionModalProps) => {
  const [selectedNFT, setSelectedNFT] = useState<number[] | string[]>([]);

  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{
        base: 'bg-white text-black sm:min-w-[600px] lg:min-w-[960px] min-w-[343px]',
        content: 'overflow-visible pr-1 lg:pr-5',
      }}
      isCloseHidden={isCloseHidden}
    >
      <div className="uppercase md:text-[40px] text-[32px] leading-10 font-secondary">
        Select nft
      </div>
      <div className="overflow-y-auto h-[427px] pt-2 pr-3">
        <Controller
          name={`${STEP_TWO_FIELD}.${NFT_FIELD}`}
          render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
            <RadioGroup
              value={selectedNFT}
              onChange={(...rest) => {
                setSelectedNFT(...rest);
                onChange(...rest);
              }}
              className="grid lg:grid-cols-3 grid-cols-2 gap-4 text-xs"
            >
              {NFTCollection.map((nft) => (
                <Field key={nft.id} className="relative">
                  <Radio value={nft}>
                    {({ checked }) => (
                      <>
                        {checked && (
                          <div className="absolute top-0 right-0 w-[30px] h-[30px] rounded-[0px_16px_0px_48px] bg-black flex items-center justify-center z-50">
                            <Tick />
                          </div>
                        )}
                        <ExistingCollectionCard
                          classNames={{
                            base: classes(
                              'p-0 w-auto md:max-w-[332px] md:min-w-[284px] opacity-50',
                              checked && 'opacity-100 shadow-[2px_2px_0px_2px_#1717171a]'
                            ),
                            heading: 'font-primary text-[10px] md:text-[20px]',
                            date: 'text-[8px] md:text-[12px]',
                            title: 'text-[8px] md:text-[12px]',
                            dot: 'hidden',
                            bgImage: 'px-[10px]',
                          }}
                          collectionName={nft.name}
                          mintedDate={nft.mintedDate}
                          title={nft.network}
                          featureImage={nft.featureImage}
                          chains={nft.chains}
                        />
                      </>
                    )}
                  </Radio>
                </Field>
              ))}
            </RadioGroup>
          )}
        />
      </div>
      <div className="flex gap-4 pt-4 flex-row md:pt-8">
        <Button
          color="black"
          variant="outlined"
          className="w-full justify-center"
          onClick={() => setIsOpen(false)}
        >
          Back
        </Button>
        <Button color="black" className="w-full justify-center" onClick={handleSaveClick}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { CopyIcon, DownArrow, HorizontalMenu } from 'components/Icons';
import { CrossIcon } from 'components/Icons/CrossIcon';
import LogoutIcon from 'components/Icons/LogoutIcon';
import UserGroup from 'components/Icons/UserGroup';
import Wallet from 'components/Icons/Wallet';
import { truncate } from 'utils';

const ACCOUNT_TITLE_CLASS = 'text-[16px] font-semibold';

export const UserProfile = () => {
  return (
    <Popover id="userprofile" className={'w-12 h-12'}>
      <PopoverButton>
        <img
          className="w-12 h-12 rounded-full cursor-pointer border object-cover min-h-[48px] min-w-[48px]"
          src="/demo/bald_style.png"
          alt="Rounded avatar"
        />
      </PopoverButton>
      <PopoverPanel
        anchor="bottom start"
        className="md:absolute !fixed !top-auto !bottom-0 md:!top-[90px] md:right-[35px] z-10 md:w-[480px] !max-w-full w-full bg-white shadow-lg  border border-[#DCDCDC] rounded-[20px] h-[100%] md:h-[81%]"
      >
        <div className="flex flex-row p-4 w-full border-b-[1px] border-[#DCDCDC] md:hidden">
          <h6 className="text-center w-full text-[16px] font-medium">Account</h6>
          <PopoverButton className={'absolute right-3'}>
            <CrossIcon stroke="#000" />
          </PopoverButton>
        </div>
        {/* Hero Profile */}
        <div className="flex flex-col gap-[16px] md:gap-[28px] p-6 px-[16px] md:p-6">
          <div className="hidden md:block">
            <h2 className="font-secondary text-[32px] uppercase leading-[28px]">Accounts</h2>
            <p className="text-[12px]">Manange your wallets and lorem ipsum dorom...</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <img
              className="w-[88px] h-[88px] rounded-full cursor-pointer border object-cover"
              src="/demo/bald_style.png"
              alt="Rounded avatar"
            />
            <h3 className="text-[24px] font-semibold">Account12</h3>
          </div>
          <div className="flex w-full gap-3">
            <div className="flex flex-row items-center justify-between gap-2 border-[1px] border-[#DCDCDC] rounded-[55px] p-2 pr-3 w-full">
              <div className="flex flex-row items-center gap-2">
                <img
                  alt="substrate-wallet-icon"
                  src={'/wallets/enkrypt.svg'}
                  className="h-8 w-8 rounded-full"
                />
                <div className="text-[14px]">
                  {truncate('0x3ccBb917694318fe50d1bA26F54883a980a29465', 10)}
                </div>
              </div>
              <CopyIcon />
            </div>
            <div className="p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
              <HorizontalMenu />
            </div>
          </div>
        </div>
        {/* Hero Profile End*/}

        <div className="flex flex-row md:w-full mx-[16px] md:mx-[0px] md:px-[24px] py-[16px] border-t-[1px] border-[#DCDCDC]">
          <div className="flex flex-row gap-4 w-full">
            <img
              alt="substrate-wallet-icon"
              src={'/wallets/enkrypt.svg'}
              className="h-12 w-12 rounded-full"
            />
            <div className="flex flex-col gap-2">
              <p className={ACCOUNT_TITLE_CLASS}>Account18</p>
              <p className="text-[12px]">
                {truncate('0x3ccBb917694318fe50d1bA26F54883a980a29465', 10)}
              </p>
            </div>
          </div>
          <div className="p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
            <HorizontalMenu />
          </div>
        </div>

        <div className="flex flex-row md:w-full mx-[16px] md:mx-[0px] md:px-[24px] py-[16px] border-t-[1px] border-[#DCDCDC]">
          <div className="flex flex-row gap-4 w-full">
            <img
              alt="substrate-wallet-icon"
              src={'/wallets/enkrypt.svg'}
              className="h-12 w-12 rounded-full"
            />
            <div className="flex flex-col gap-2">
              <p className={ACCOUNT_TITLE_CLASS}>Account18</p>
              <p className="text-[12px]">
                {truncate('0x3ccBb917694318fe50d1bA26F54883a980a29465', 10)} · External Wallet
              </p>
            </div>
          </div>
          <div className="p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full h-[48px] w-[48px]">
            <HorizontalMenu />
          </div>
        </div>

        <div className="flex flex-row md:w-full mx-[16px] md:mx-[0px] md:px-[24px] py-[16px] border-t-[1px] border-[#DCDCDC]">
          <div className="flex flex-row gap-4 w-full">
            <div className="flex items-center justify-center p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
              <UserGroup />
            </div>
            <div className="flex flex-col gap-2">
              <p className={ACCOUNT_TITLE_CLASS}>+ 12 accounts</p>
              <p className="text-[12px]">Click to expend the view</p>
            </div>
          </div>
          <div className="flex items-center justify-center p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
            <DownArrow />
          </div>
        </div>

        <div className="flex flex-row md:w-full mx-[16px] md:mx-[0px] md:px-[24px] py-[16px] border-t-[1px] border-[#DCDCDC]">
          <div className="flex flex-row items-center  gap-4 w-full font-semibold">
            Add another wallet
          </div>
          <div className="flex items-center justify-center p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
            <Wallet />
          </div>
        </div>

        <div className="flex flex-row md:w-full mx-[16px] md:mx-[0px] md:px-[24px] py-[16px] border-t-[1px] border-[#DCDCDC]">
          <div className="flex flex-row items-center  gap-4 w-full font-semibold">
            Sign out of all accounts
          </div>
          <div className="flex items-center justify-center p-[14px] bg-[#171717] bg-opacity-[5%] rounded-full">
            <LogoutIcon />
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
};

import { z } from 'zod';

import {
  DESTINATION_COLLECTION_FIELD,
  DESTINATION_FIELD,
  NFT_FIELD,
  SOURCE_COLLECTION_FIELD,
  SOURCE_FIELD,
  STEP_ONE_FIELD,
  STEP_TWO_FIELD,
} from './formFields';

export const TOKEN_TYPES = ['Normal', 'ANLOG-404'] as const;

const SOURCE_ERROR = 'Source is required';
const sourceFieldSchema = z.object(
  {
    label: z.string({ required_error: SOURCE_ERROR }).min(1, SOURCE_ERROR),
    value: z.string({ required_error: SOURCE_ERROR }).min(1, SOURCE_ERROR),
    icon: z.any(),
  },
  { required_error: SOURCE_ERROR }
);

const DESTINATION_ERROR = 'Destination is required';
const destinationFieldSchema = z.object(
  {
    label: z.string({ required_error: DESTINATION_ERROR }).min(1, DESTINATION_ERROR),
    value: z.string({ required_error: DESTINATION_ERROR }).min(1, DESTINATION_ERROR),
    icon: z.any(),
  },
  { required_error: DESTINATION_ERROR }
);

const wizardOneSchema = z.object({
  [SOURCE_FIELD]: sourceFieldSchema,
  [DESTINATION_FIELD]: destinationFieldSchema,
});

const wizardTwoSchema = z.object(
  {
    [SOURCE_COLLECTION_FIELD]: z
      .string({ required_error: 'Source collection is required!' })
      .min(1, 'Source collection is required!'),
    [DESTINATION_COLLECTION_FIELD]: z
      .string({ required_error: 'Destination collection is required!' })
      .min(1, 'Destination collection is required!'),
    [NFT_FIELD]: z.any(),
  },
  {
    required_error: 'Source and destination are required',
  }
);

export const BridgeNFTSchema = z.object({
  [STEP_ONE_FIELD]: wizardOneSchema,
  [STEP_TWO_FIELD]: wizardTwoSchema,
});

export type CreateBridgeNFTFormType = z.infer<typeof BridgeNFTSchema>;

import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { STEP_ONE_FIELD } from '../../formFields';
import { ExistingCollectionFormType } from '../../WizardSchema';

const useExistingCollectionStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { trigger } = useFormContext<ExistingCollectionFormType>();
  const navigate = useNavigate();

  const onNext = async () => {
    const isValid = await trigger(STEP_ONE_FIELD);

    if (isValid) {
      handleActiveStep((step) => step + 1);
    }
  };

  return { navigate, onNext };
};

export default useExistingCollectionStep1;

import { z } from 'zod';

import {
  COLLECTION_NAME_FIELD,
  COLLECTION_SYMBOL_FIELD,
  ERC721_address_FIELD,
  FRACTIONS_FIELD,
  STEP_ONE_FIELD,
  STEP_THREE_FIELD,
  STEP_TWO_FIELD,
  TOKEN_TYPE_FIELD,
} from './formFields';

export const TOKEN_TYPES = ['Normal', 'ANLOG-404'] as const;

const wizardOneSchema = z.object({
  [ERC721_address_FIELD]: z
    .string({ required_error: 'ERC721 address is required' })
    .min(1, 'ERC721 address is required'),
});

const wizardTwoSchema = z.object({
  [COLLECTION_NAME_FIELD]: z
    .string({ required_error: 'Collection Name is required' })
    .min(1, 'Collection Name is required'),
  [COLLECTION_SYMBOL_FIELD]: z
    .string({ required_error: 'Collection Symbol is required' })
    .min(1, 'Collection Symbol is required'),
  [TOKEN_TYPE_FIELD]: z
    .string({ required_error: 'Token Type is required' })
    .min(1, 'Token Type is required'),
});

const wizardThreeSchema = z
  .object({
    [FRACTIONS_FIELD]: z
      .string({ required_error: 'Total Supply is required' })
      .min(1, 'Total Supply cannot be empty')
      .transform((val) => parseInt(val, 10))
      .pipe(
        z
          .number()
          .int()
          .min(1)
          .max(10000)
          .refine((val) => !isNaN(val), {
            message: 'Invalid number',
          })
      ),
  })
  .optional(); // Step 3 is optional and only validated conditionally

export const ExistingCollectionFormSchema = z
  .object({
    [STEP_ONE_FIELD]: wizardOneSchema,
    [STEP_TWO_FIELD]: wizardTwoSchema,
    [STEP_THREE_FIELD]: wizardThreeSchema, // Include step 3 but make it optional
  })
  .refine(
    (data) => {
      const tokenType = data[STEP_TWO_FIELD][TOKEN_TYPE_FIELD];

      if (tokenType !== 'Normal' && !data[STEP_THREE_FIELD]) {
        return false; // Step 3 is required if token type is not "Normal"
      }

      return true;
    },
    {
      message: 'Total Supply are required for non-Normal Token Types',
      path: [STEP_THREE_FIELD],
    }
  );

export type ExistingCollectionFormType = z.infer<typeof ExistingCollectionFormSchema>;

export const useCaseData = [
  {
    key: 'Real Estate',
    data: [
      {
        heading: 'Security',
        menu: [
          {
            title: 'Decentralised Security Protocols',
            desc: 'Your real estate assets are guarded by a decentralized fortress across multiple blockchains. By leveraging the best of each network, we reduce vulnerabilities and make sure your assets stay safe from potential threats, like a digital neighborhood watch on steroids',
          },
          {
            title: 'Smart Contract Integrity',
            desc: 'Pixelport’s smart contracts are like the digital notaries you didn’t know you needed—automatically enforcing the terms of your real estate deals with zero room for fraud. Everything runs transparently and reliably so you can skip the legalese and trust the tech.',
          },
          {
            title: 'Cross Chain Verification',
            desc: 'With cross-chain verification, your real estate holdings are recognized and validated across all supported blockchains. It’s like having a universal deed that’s acknowledged everywhere, eliminating any discrepancies and ensuring your ownership is rock-solid.',
          },
        ],
      },
      {
        heading: 'Trade',
        menu: [
          {
            title: 'Cross-Chain Asset Transfers',
            desc: 'Smoothly transfer real estate assets across different blockchain networks. This cross-chain functionality offers you the freedom to trade properties without being tied down to one blockchain—your assets, your rules.',
          },
          {
            title: 'Broader Market Access',
            desc: 'Think globally, trade digitally. Access a worldwide market of real estate investors and buyers across multiple blockchains. Pixelport helps you break through those pesky geographical and blockchain barriers connecting you with the right opportunities faster.',
          },
          {
            title: 'Faster and Cross-Efficient Transactions',
            desc: 'Close deals quicker than a coffee break. Pixelport’s streamlined processes minimize gas fees and speed up transactions so you can seal the deal without the usual hassle or wait times. Efficiency is the name of the game.',
          },
        ],
      },
      {
        heading: 'Management',
        menu: [
          {
            title: 'Decentralized Property Management',
            desc: 'Manage your real estate assets like a pro, without the middlemen. Pixelport’s decentralized platform gives you full control over your properties which allows you to handle everything from rental agreements to maintenance requests directly through the blockchain.',
          },
          {
            title: 'Real-Time Asset Tracking',
            desc: 'Keep tabs on your properties in real-time no matter where they’re located or on which blockchain they reside. Pixelport provides instant updates and detailed insights into your real estate portfolio so you’re always in the loop and can make informed decisions on the fly.',
          },
          {
            title: 'Automated Compliance',
            desc: 'Forget the paperwork. Pixelport automates compliance with local regulations and smart contract rules, ensuring your properties meet all the necessary legal requirements.',
          },
        ],
      },
    ],
  },
  {
    key: 'Gaming',
    data: [
      {
        heading: 'Play',
        menu: [
          {
            title: 'Cross-Chain Game Assets',
            desc: 'Take your in-game assets on a multi-blockchain adventure. With Pixelport, your weapons, skins, and characters aren’t locked to one game or chain—they’re free to roam across supported platforms, giving you more ways to use and trade your gear. ',
          },
          {
            title: 'Interoperable NFTs',
            desc: 'Why settle for one game when your NFTs can be part of many? Pixelport enables interoperable NFTs that work across different games and ecosystems so your digital assets gain value the more you play. ',
          },
          {
            title: 'Secure Ownership',
            desc: 'Protect your prized in-game assets with blockchain security. Pixelport ensures that your gaming NFTs are securely stored and tamper-proof so you can game with confidence knowing your hard-earned loot is safe. ',
          },
        ],
      },
      {
        heading: 'Trade',
        menu: [
          {
            title: 'Decentralized Marketplaces',
            desc: 'Power up the player economy with decentralized marketplaces where you call the shots. Pixelport lets gamers buy, sell, and trade assets directly with each other, cutting out the middlemen and allowing you to set the rules. ',
          },
          {
            title: 'Fractional Ownership of In-Game Assets',
            desc: 'Trade your way into high-value gear, even if you can’t afford the whole thing. Pixelport allows for fractional ownership of in-game assets, making top-tier items more accessible. Own a piece of the action and still enjoy the benefits.',
          },
          {
            title: 'Tokenized Rewards',
            desc: 'Trade your time and skills for more than just points. Pixelport integrates tokenized rewards into games which gives you tradable assets that grow in value as you level up. It’s earning while playing—what could be better?',
          },
        ],
      },
      {
        heading: 'Engage',
        menu: [
          {
            title: 'Dynamic In-Game Events',
            desc: 'Engage with the game in new and exciting ways. Pixelport powers dynamic in-game events with NFTs, creating time-limited, exclusive content that keeps the game fresh.',
          },
          {
            title: 'Player Governance',
            desc: 'Engage with the community and shape the game’s future. Pixelport supports decentralized decision-making within games, allowing players to vote on changes, updates, and new content.',
          },
          {
            title: 'Integrated Identity Across Games',
            desc: 'Engage across multiple games with a unified identity. Pixelport lets you create a cross-game identity that’s recognized across different titles and platforms. Your achievements, reputation, and status follow you, making your gaming persona slick.',
          },
        ],
      },
    ],
  },
  {
    key: 'Music & Entertainment',
    data: [
      {
        heading: 'Create',
        menu: [
          {
            title: 'Cross-Chain Music & Media',
            desc: 'Create and distribute your music and media across multiple blockchains without limits. With Pixelport, your tracks, videos, and digital art can reach wider audiences by moving seamlessly between supported platforms. ',
          },
          {
            title: 'Interoperable Media NFTs',
            desc: 'Create once, use everywhere. Pixelport enables interoperable media NFTs that work across different platforms, allowing your music and entertainment assets to gain value with every play. ',
          },
          {
            title: 'Secure Rights Management',
            desc: 'Create with confidence knowing your rights are protected. Pixelport secures your music and media NFTs with tamper-proof blockchain technology to make sure your work is always credited to you. ',
          },
        ],
      },
      {
        heading: 'Share',
        menu: [
          {
            title: 'Decentralized Distribution',
            desc: 'Share your art with the world on your terms. Pixelport’s decentralized distribution lets you bypass traditional gatekeepers helping you connect directly with your audience.',
          },
          {
            title: 'Fan Engagement & Rewards',
            desc: 'Share the love with your fans by offering exclusive content and rewards. Pixelport enables artists to engage with their audience through tokenized rewards giving your most loyal fans a chance to own a piece of your work.',
          },
          {
            title: 'Royalty Tracking & Payments',
            desc: 'Share your success and get paid fairly. Pixelport automates royalty tracking and payments making sure you receive your earnings directly and transparently. Every play, download, or stream is accounted for so you can focus on making more hits!',
          },
        ],
      },
      {
        heading: 'Experience',
        menu: [
          {
            title: 'Interactive Live Events',
            desc: 'Experience music and entertainment like never before with interactive live events powered by NFTs. Pixelport allows artists to create unique, time-limited experiences that fans can participate in, from virtual concerts to exclusive listening parties. ',
          },
          {
            title: 'Audience Governance',
            desc: 'Experience a new level of fan involvement with audience governance. Pixelport enables decentralized decision-making, allowing your fans to vote on everything from setlists to future projects. ',
          },
          {
            title: 'Unified Media Identity',
            desc: 'Experience a seamless transition across platforms with a unified media identity. Pixelport lets you maintain a consistent presence across different platforms and services, ensuring your brand and content are recognized wherever you go. ',
          },
        ],
      },
    ],
  },
  {
    key: 'Decentralized Finance',
    data: [
      {
        heading: 'Invest',
        menu: [
          {
            title: 'Cross-Chain DeFi Assets',
            desc: 'Grow your investment abilities by moving your DeFi assets across chains. Your portfolio isn’t confined to one ecosystem with Pixelport—you can seamlessly access the best opportunities wherever they exist maximizing your returns across the board.',
          },
          {
            title: 'Secure Yield Farming',
            desc: 'Yield farming just got a lot safer. Pixelport’s infrastructure ensures that your staking and farming activities are protected by robust, tamper-proof protocols. Focus on growing your assets with the confidence that your investments are secured against breaches.',
          },
        ],
      },
      {
        heading: 'Trade',
        menu: [
          {
            title: 'Decentralized Exchanges (DEXs)',
            desc: 'Dive into decentralized exchanges and trade your DeFi assets with ease. Pixelport connects you to a broad network of DEXs, offering you the freedom to swap NFTs and assets with minimal friction.',
          },
          {
            title: 'Cross-Chain Liquidity Pools',
            desc: 'Enhance your trading power by accessing liquidity across multiple blockchains. Pixelport’s cross-chain liquidity pools open up greater earning potential and minimize slippage.',
          },
        ],
      },
      {
        heading: 'Manage',
        menu: [
          {
            title: 'Decentralized Portfolio Management',
            desc: 'Take control of your DeFi portfolio with a comprehensive, decentralized management tool. Pixelport gives you a unified dashboard where you can oversee your assets across different blockchains, helping you make informed decisions in real-time.',
          },
          {
            title: 'Real-Time Asset Tracking',
            desc: 'Keep your finger on the pulse of your investments with real-time asset tracking. You’ll get instant updates on your DeFi holdings with Pixeport which make sure you’re always in the know and ready to act as market conditions shift.',
          },
          {
            title: 'Smart Contract Auditing',
            desc: 'Trust in the security of your DeFi investments with automated smart contract auditing. Pixelport ensures that the protocols you engage with are thoroughly vetted.',
          },
        ],
      },
    ],
  },
  {
    key: 'Sports and Memorabilia ',
    data: [
      {
        heading: 'Collect',
        menu: [
          {
            title: 'Authentic Sports Memorabilia NFTs',
            desc: 'Step into the world of sports history with authenticated memorabilia NFTs. Each digital collectible is verifiably authentic, providing fans with a secure way to own and trade iconic moments and items from their favorite sports',
          },
          {
            title: 'Cross-Chain Collectibles',
            desc: 'Expand your collection beyond the confines of a single platform. These sports memorabilia NFTs can move freely across multiple blockchains.',
          },
        ],
      },
      {
        heading: 'Trade',
        menu: [
          {
            title: 'Peer-to-Peer Trading',
            desc: 'Connect directly with other fans and collectors through peer-to-peer trading. Skip the middlemen and make your deals on a platform designed for true sports enthusiasts. Whether you’re hunting for that elusive item or looking to make a trade, it’s all about personal connections.',
          },
          {
            title: 'Cross-Chain Marketplace Access',
            desc: 'Open the door to a global network of collectors by trading your memorabilia across different blockchains. This broad access increases your chances of finding the perfect match for your items.',
          },
          {
            title: 'Dynamic Pricing Mechanisms',
            desc: 'Stay competitive in the market with pricing that adapts in real-time. Our platform integrates up-to-date data and analytics, helping you set accurate prices and secure the best deals whether you’re buying or selling.',
          },
        ],
      },
      {
        heading: 'Engage',
        menu: [
          {
            title: 'Fan-Centric Experiences',
            desc: 'Dive deeper into your favorite sports through unique fan experiences. Special NFTs unlock access to virtual meet-and-greets, exclusive Q&A sessions, and more.',
          },
          {
            title: 'Community-Driven Content',
            desc: 'Influence the future of sports memorabilia by getting involved in community-driven content. Vote on upcoming NFT releases, collaborate on designs, and suggest new types of collectibles.',
          },
          {
            title: 'Unified Fan Identity',
            desc: 'Showcase your loyalty and achievements across platforms with a unified digital fan identity. Let your collection and status follow you wherever you go, making your dedication to the game recognized in every community you’re part of.',
          },
        ],
      },
    ],
  },
  {
    key: 'Crowdfunding and Investments',
    data: [
      {
        heading: 'Fund',
        menu: [
          {
            title: 'Tokenized Crowdfunding',
            desc: 'By turning your project into tokens, you can attract backers from around the globe who believe in your vision. These tokens give investors a tangible stake, transforming supporters into shareholders.',
          },
          {
            title: 'Cross-Chain Investment Pools',
            desc: 'Instead of being limited to one blockchain, your crowdfunding campaign can tap into multiple networks, drawing in more participants and diversifying your capital sources.',
          },
          {
            title: 'Fractionalized Equity',
            desc: 'Make investment more accessible with fractionalized equity. By dividing ownership into smaller shares, you can lower the barrier to entry, allowing more people to invest in your project. This approach democratizes investment which makes it way easier for your supporters to get involved.',
          },
        ],
      },
      {
        heading: 'Invest',
        menu: [
          {
            title: 'Decentralized Investment Platforms',
            desc: 'Tired of middlemen skimming off the top? Join a decentralized investment platform where the community calls the shots. It’s transparent, it’s fair, and it’s all about giving you the control you deserve',
          },
          {
            title: 'Smart Contract-Driven Investments',
            desc: 'Put your investments on autopilot with smart contracts that ensure everything goes according to plan. No surprises, no fine print—just automated precision that keeps your funds safe and sound. ',
          },
          {
            title: 'Real-Time Performance Tracking',
            desc: 'Stay in the know with real-time tracking of your investments. No more guessing games—get instant insights and make informed decisions on the fly. It’s like having your own financial crystal ball, minus the hocus-pocus.',
          },
        ],
      },
      {
        heading: 'Grow',
        menu: [
          {
            title: 'Community-Led Project Development',
            desc: 'Be more than just an investor—be a co-creator. With community-led development, your input helps shape the projects you fund ensuring they grow in ways that reflect your values and goals. ',
          },
          {
            title: 'Profit-Sharing Mechanisms',
            desc: 'Why should only the big shots get rich? Profit-sharing mechanisms let you enjoy the spoils of success right alongside the projects you support. When they win, you win',
          },
          {
            title: 'Cross-Chain Expansion Opportunities',
            desc: 'Think big, then think bigger. Cross-chain expansion opens up new growth opportunities, allowing projects to scale and investors to diversify across different networks.',
          },
        ],
      },
    ],
  },
  {
    key: 'Governance',
    data: [
      {
        heading: 'Vote',
        menu: [
          {
            title: 'Decentralized Voting Systems',
            desc: 'participate in a governance system where your vote truly counts. Our decentralized voting system records every decision on the blockchain, ensuring transparency and security.',
          },
          {
            title: 'Cross-Chain Governance Participation',
            desc: 'Influence decisions across multiple blockchain networks without being confined to a single platform. Cross-chain governance lets you participate in shaping projects wherever they exist',
          },
          {
            title: 'Token-Based Voting Power',
            desc: 'Your investment comes with a voice. In our governance model, the more tokens you hold, the greater your voting power. This structure ensures that those most committed to a project have a stronger influence on its direction',
          },
        ],
      },
      {
        heading: 'Govern',
        menu: [
          {
            title: 'Decentralized Autonomous Organizations (DAOs)',
            desc: 'Experience governance without central control through Decentralized Autonomous Organizations (DAOs). These blockchain-based entities allow members to collectively govern projects making decisions directly and democratically.',
          },
          {
            title: 'Adaptive Governance Models',
            desc: 'Govern with flexibility through adaptive models that evolve with your project’s needs. As the community grows or objectives shift, the governance structure can be adjusted to remain effective and responsive. ',
          },
          {
            title: 'Long-Term Strategic Planning',
            desc: 'Engage in strategic planning that goes beyond immediate decisions. By participating in long-term governance, you can help set the vision and roadmap for projects',
          },
        ],
      },
    ],
  },
  {
    key: 'Digital Art & Collectibles',
    data: [
      {
        heading: 'Compatibility',
        menu: [
          {
            title: 'Cross-Chain Compatibility',
            desc: 'Expand your reach with cross-chain compatible NFTs. Whether you’re an artist or a collector, having assets that work across multiple blockchains opens up new opportunities for exposure and trading.',
          },
          {
            title: 'Collaborative Art Creation',
            desc: 'Collaborate like never before with blockchain-powered shared ownership. Pixelport enables multiple artists to co-create and co-own digital pieces with clear rights and revenue splits defined by smart contracts.',
          },
        ],
      },
      {
        heading: 'Trade',
        menu: [
          {
            title: 'Decentralized Marketplaces',
            desc: 'Trade your digital art and collectibles on decentralized marketplaces that prioritize transparency and security. No middlemen, no hidden fees—just direct transactions between buyers and sellers.',
          },
          {
            title: 'Dynamic Pricing for Art',
            desc: 'Keep up with market trends with dynamic pricing for your digital art. Pixelport provides tools that allow prices to adjust in real-time based on demand and market activity, ensuring your assets are always priced accurately. ',
          },
          {
            title: 'Fractional Ownership of High-Value Art',
            desc: 'Own a piece of the masterpiece with fractional ownership. Pixelport allows high-value digital art to be divided into smaller shares, making it accessible to more collectors. ',
          },
        ],
      },
      {
        heading: 'Showcase',
        menu: [
          {
            title: 'Virtual Galleries',
            desc: 'Exhibit your digital art in stunning virtual galleries designed to enhance the viewing experience. Pixelport offers customizable spaces where you can curate your collection, host events, and invite others to explore your work in an immersive, digital environment.',
          },
        ],
      },
    ],
  },
  // Template for new item.
  // {
  //   key: '',
  //   data: [
  //     {
  //       heading: '',
  //       menu: [
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //       ],
  //     },
  //     {
  //       heading: '',
  //       menu: [
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //       ],
  //     },
  //     {
  //       heading: '',
  //       menu: [
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //         {
  //           title: '',
  //           desc: '',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import { routes } from 'routes/routesConst';
import { useStopReloadingPage } from 'utils';

import { CreateNFTStep2, CreateNFTStep3, MintNFTStep1 } from './components';
import { CreateNFTFormSchema } from './WizardSchemaForCreateNFT';

export const MintNFT = () => {
  const [activeStep, handleActiveStep] = useState(1);
  const collection = {
    name: 'Dummy Collection',
    id: '123',
  };

  const breadcrumbData = [
    {
      path: `/${routes.nftHub}`,
      label: 'Collections',
    },
    {
      path: `/${routes.nftHub}/${collection.id}`,
      label: collection?.name,
    },
    {
      path: `/${routes.nftHub}/${collection.id}/${routes.createNFT}`,
      label: 'Mint NFT',
    },
  ];

  const form = useForm({
    resolver: zodResolver(CreateNFTFormSchema),
  });

  const { dirtyFields } = form.formState;

  const isDirty = Object.keys(dirtyFields).length > 0;

  useStopReloadingPage(isDirty);

  return (
    <div>
      <Form className="lg:py-8 py-4 pb-0 mx-auto space-y-6" action="/hello" form={form}>
        {activeStep === 1 && (
          <MintNFTStep1 handleActiveStep={handleActiveStep} breadcrumbData={breadcrumbData} />
        )}
        {activeStep === 2 && (
          <CreateNFTStep2 handleActiveStep={handleActiveStep} breadcrumbData={breadcrumbData} />
        )}
        {activeStep === 3 && (
          <CreateNFTStep3 handleActiveStep={handleActiveStep} breadcrumbData={breadcrumbData} />
        )}
      </Form>
    </div>
  );
};

export default MintNFT;

// StepOne
export const STEP_ONE_FIELD = 'stepOne';
export const SOURCE_FIELD = 'source';
export const DESTINATION_FIELD = 'destination';

// StepTwo
export const STEP_TWO_FIELD = 'stepTwo';
export const CHAIN_FIELD = 'chain';
export const SOURCE_COLLECTION_FIELD = 'sourceCollection';
export const DESTINATION_COLLECTION_FIELD = 'destinationCollection';
export const NFT_FIELD = 'nft';

import { Props } from './types';

export function ContactsIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <mask
        id="a"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M24 0H0v24h24V0Z" />
      </mask>
      <g mask="url(#a)">
        <mask
          id="b"
          width={24}
          height={24}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M24 0H0v24h24V0Z" />
        </mask>
        <g mask="url(#b)">
          <mask
            id="c"
            width={24}
            height={24}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path fill="#fff" d="M24 0H0v24h24V0Z" />
          </mask>
          <g mask="url(#c)">
            <path
              fill="#000"
              d="M7.974 11.709c2.087 0 3.776-1.612 3.776-3.604 0-1.993-1.69-3.605-3.776-3.605-2.088 0-3.777 1.612-3.777 3.604 0 1.993 1.69 3.605 3.777 3.605Zm2.589 1.03h-.28a5.52 5.52 0 0 1-2.31.515c-.829 0-1.604-.193-2.309-.515h-.28c-2.144 0-3.884 1.66-3.884 3.707v.927c0 .853.725 1.545 1.618 1.545h9.71c.894 0 1.619-.692 1.619-1.545v-.927c0-2.047-1.74-3.707-3.884-3.707Zm7.12-1.03c1.788 0 3.238-1.384 3.238-3.09 0-1.705-1.45-3.09-3.237-3.09-1.787 0-3.237 1.385-3.237 3.09 0 1.706 1.45 3.09 3.237 3.09Zm1.62 1.03h-.129a4.735 4.735 0 0 1-1.49.257 4.735 4.735 0 0 1-1.49-.257h-.128a3.88 3.88 0 0 0-1.879.495c.823.847 1.339 1.97 1.339 3.212v1.236c0 .071-.017.139-.02.206h5.954c.893 0 1.618-.692 1.618-1.545 0-1.992-1.689-3.604-3.776-3.604Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

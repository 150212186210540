import { useState } from 'react';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { FieldErrors, FieldValues, useForm as useReactHookFrom } from 'react-hook-form';
import toast from 'react-hot-toast';

import { FormProps } from './types';
import { getErrorMessage } from './utils/getErrorMessage';

const useForm = ({
  form,
  onSubmit,
  onError,
  action,
  processFormValues,
  axiosInstance,
  onSuccess,
  method,
  successMessage,
  axiosParams,
  axiosHeaders,
  ...rest
}: Omit<FormProps, 'children'>) => {
  const ourForm = useReactHookFrom({ ...rest });
  const newForm = form || ourForm;
  const [isLoading, setIsLoading] = useState(false);

  const onResetHandler = () => {
    newForm.reset();
  };

  const onSubmitHandler = async (formValues: FieldValues) => {
    setIsLoading(true);
    let hasError = false;
    if (onSubmit) onSubmit(formValues);

    if (action) {
      try {
        const formData = (processFormValues && processFormValues(formValues)) || formValues;

        const axiosConfig: AxiosRequestConfig = {
          method: method,
          url: action,
          data: formData,
          params: axiosParams,
          headers: axiosHeaders,
        };

        const response = await (
          (axiosInstance && axiosInstance(axiosConfig)) ||
          axios(axiosConfig)
        ).catch((error) => {
          hasError = true;
          // const message = getErrorMessage(error);

          // toast.error(message || error.message);
          toast.success('Api call....');
        });

        if (!hasError) {
          onSuccess && onSuccess(response);

          toast.success(successMessage || 'Successfully called API');
        }
      } catch (error) {
        const message = getErrorMessage(error as AxiosError);

        toast.error(message || (error as AxiosError).message);
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const onErrorHandler = (fieldErrors: FieldErrors) => {
    onError && onError(fieldErrors);
  };

  // This is the form submit event handler
  const onFormSubmitHandler = newForm.handleSubmit(onSubmitHandler, onErrorHandler);

  return {
    newForm,
    onResetHandler,
    onFormSubmitHandler,
    isLoading,
  };
};

export default useForm;

import { useEffect } from 'react';

import { useBlocker } from 'react-router-dom';

export const useStopReloadingPage = (isDirty: boolean) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker?.location?.pathname) {
      if (isDirty) {
        const isConfirm = confirm('Changes that you made may not be saved.');

        if (isConfirm) {
          blocker && blocker.proceed?.();
        }
      }
    }
  }, [blocker, isDirty]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = 'Changes that you made may not be saved.'; // This triggers the browser's confirmation dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
};

export default useStopReloadingPage;

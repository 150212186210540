import { Props } from './types';

export function CardPlus(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8571 6.8571V0.73668H7.5699V0H8.4301V0.73668H9.1468L9.1429 6.8571H15.2633V7.6655H16V8.4778H15.2633V9.1429H9.1429V15.2633H8.4301V16H7.5699V15.2633H6.8571V9.1429H0.6889V8.4778H0V7.6655H0.6889V6.8571H6.8571Z"
        fill={props.fill}
      />
    </svg>
  );
}

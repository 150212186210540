import { Props } from './types';

export const ToastSuccessIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#4DB50E" />
      <g clipPath="url(#clip0_922_93433)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1148 17.7477L15.8624 17.7477L15.8624 16.4954L17.1148 16.4954L17.1148 17.7477ZM14.6101 16.4954L13.3577 16.4954L13.3577 17.7477L14.6101 17.7477L14.6101 19.0001L15.8624 19.0001L15.8624 17.7477L14.6101 17.7477L14.6101 16.4954Z"
          fill="white"
        />
        <path
          d="M15.8624 17.7477L17.1148 17.7477L17.1148 16.4954L15.8624 16.4954L15.8624 17.7477ZM15.8624 17.7477L15.8624 19.0001L14.6101 19.0001L14.6101 17.7477M15.8624 17.7477L14.6101 17.7477M14.6101 17.7477L13.3577 17.7477L13.3577 16.4954L14.6101 16.4954L14.6101 17.7477Z"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M18.3671 15.2431L17.1147 15.2431L17.1147 16.4954L18.3671 16.4954L18.3671 15.2431Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M19.6207 14L18.3684 14L18.3684 15.2524L19.6207 15.2524L19.6207 14Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M12.1062 15.2429L12.1062 16.4953L13.3588 16.4954L13.3587 15.2429L12.1062 15.2429Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M22.6667 14V12.6666H22V11.3333H21.3334V10.6666H20.6667V9.99996H19.3334V9.33329H18V8.66663H14V9.33329H12.6667V9.99996H11.3334V10.6666H10.6667V11.3333H10V12.6666H9.33335V14H8.66669V18H9.33335V19.3333H10V20.6666H10.6667V21.3333H11.3334V22H12.6667V22.6666H14V23.3333H18V22.6666H19.3334V22H20.6667V21.3333H21.3334V20.6666H22V19.3333H22.6667V18H23.3334V14H22.6667ZM22 18H21.3334V19.3333H20.6667V20.6666H19.3334V21.3333H18V22H14V21.3333H12.6667V20.6666H11.3334V19.3333H10.6667V18H10V14H10.6667V12.6666H11.3334V11.3333H12.6667V10.6666H14V9.99996H18V10.6666H19.3334V11.3333H20.6667V12.6666H21.3334V14H22V18Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_922_93433">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { Dispatch, SetStateAction } from 'react';

import { Modal } from 'components';
import { AddIcon, CardPlus } from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import { combineRoutes } from 'routes/routesConst';
import { HTMLAttributes } from 'types';

import AddCollectionCard from './AddCollectionCard';

export interface AddCollectionModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
}

export const AddCollectionModal = ({ isOpen, className, setIsOpen }: AddCollectionModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{ base: 'bg-white text-black' }}
      isCloseHidden
    >
      <div className="uppercase md:text-[40px] text-[32px] leading-10 font-secondary">
        create a new collection
      </div>
      <div className="pt-1 text-sm md:text-base text-textGray">
        Your vision, your collection. Start from scratch or bring your NFTs to Pixelport.
      </div>
      <div className="flex flex-col gap-4 pt-4 md:flex-row md:pt-8">
        <AddCollectionCard
          className="w-[320px] cursor-pointer"
          classNames={{
            base: 'justify-center pt-9',
            subtext: 'text-center !leading-none text-[16px] md:text-[18px] pb-9',
          }}
          cardTheme="dark"
          button={<CardPlus fill="#fff" />}
          subtext="create new collection"
          onClick={() => navigate(`${combineRoutes.createCollection}?new`)}
          hideBackgroundImage
        />
        <AddCollectionCard
          className="w-[320px] bg-transparent cursor-pointer"
          classNames={{
            base: 'justify-center pt-4 bg-transparent',
            subtext: 'text-center !leading-none text-[16px] md:text-[18px] pb-4',
          }}
          button={<AddIcon fill="#fff" />}
          subtext="Add an existing collection"
          onClick={() => navigate(`${combineRoutes.createCollection}?existing`)}
          hideBackgroundImage
        />
      </div>
    </Modal>
  );
};

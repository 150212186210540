import { Props } from './types';

export function FramesIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <mask
        id="a"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M24 0H0v24h24V0Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#000"
          d="M5 13.928v.784c0 .048-.02.094-.055.128a.191.191 0 0 1-.133.053H3.188a.191.191 0 0 1-.132-.053.178.178 0 0 1-.055-.128v-1.567c0-.048.02-.094.055-.128a.191.191 0 0 1 .132-.053H4V7.18h-.813a.191.191 0 0 1-.132-.053A.178.178 0 0 1 3 6.998V5.43c0-.048.02-.094.055-.128a.191.191 0 0 1 .132-.053h1.626c.05 0 .097.02.132.053.035.034.055.08.055.128v.783h9v-.783c0-.048.02-.094.055-.128a.191.191 0 0 1 .133-.053h1.624c.05 0 .098.02.133.053.035.034.055.08.055.128v1.567c0 .048-.02.094-.055.128a.191.191 0 0 1-.133.053H15v5.785h.813c.05 0 .097.02.132.053.035.034.055.08.055.128v1.567c0 .048-.02.094-.055.128a.191.191 0 0 1-.133.053h-1.624a.191.191 0 0 1-.133-.053.178.178 0 0 1-.055-.128v-.784H5ZM20 12v-.964h.813c.05 0 .097-.02.132-.053a.177.177 0 0 0 .055-.128V9.288a.177.177 0 0 0-.055-.128.191.191 0 0 0-.133-.053h-1.625a.19.19 0 0 0-.132.053.177.177 0 0 0-.055.128v.784h-3.25v2.169H16c.414 0 .75.324.75.723v1.929c0 .4-.336.723-.75.723h-2a.737.737 0 0 1-.75-.723v-.241H9v2.17h-.812a.191.191 0 0 0-.133.052.178.178 0 0 0-.055.128v1.567c0 .048.02.094.055.128a.191.191 0 0 0 .133.053h1.624c.05 0 .098-.02.133-.053a.177.177 0 0 0 .055-.128v-.783h9v.783c0 .048.02.094.055.128a.19.19 0 0 0 .133.053h1.625c.05 0 .097-.02.132-.053a.177.177 0 0 0 .055-.128v-1.567a.177.177 0 0 0-.055-.128.191.191 0 0 0-.133-.052H20V12Z"
        />
      </g>
    </svg>
  );
}

import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { getAllErrorMessages } from 'utils/getAllErrorMessages';

import { CreateBridgeNFTFormType } from '../../CreateBridgeNFTSchema';
import {
  DESTINATION_COLLECTION_FIELD,
  DESTINATION_FIELD,
  NFT_FIELD,
  SOURCE_COLLECTION_FIELD,
  SOURCE_FIELD,
  STEP_ONE_FIELD,
  STEP_TWO_FIELD,
} from '../../formFields';

export const useCreateBridgeNFTStep2 = () => {
  const [isSelectCollectionModalOpen, setSelectCollectionModalOpen] = useState(false);
  const [isSelectNFTModalOpen, setIsSelectNFTModalOpen] = useState(false);
  const [isDestinationCollectionModalOpen, setDestinationCollectionModalOpen] = useState(false);
  const [isCongratulationModelOpen, setIsCongratulationModelOpen] = useState(false);

  const {
    getValues,
    trigger,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext<CreateBridgeNFTFormType>();
  const sourceChain = getValues(`${STEP_ONE_FIELD}.${SOURCE_FIELD}`);
  const destinationChain = getValues(`${STEP_ONE_FIELD}.${DESTINATION_FIELD}`);
  const sourceCollection = getValues(`${STEP_TWO_FIELD}.${SOURCE_COLLECTION_FIELD}`);
  const destinationCollection = getValues(`${STEP_TWO_FIELD}.${DESTINATION_COLLECTION_FIELD}`);
  const nft = getValues(`${STEP_TWO_FIELD}.${NFT_FIELD}`);
  const allErrors = getAllErrorMessages(errors);

  const onNext = async () => {
    const isValid = await trigger(STEP_TWO_FIELD);

    if (!isDirty) {
      setValue('stepTwo.sourceCollection', '', { shouldDirty: true });
    }

    if (isValid) {
      setIsCongratulationModelOpen(true);
    }
  };

  return {
    isSelectCollectionModalOpen,
    setSelectCollectionModalOpen,
    isSelectNFTModalOpen,
    setIsSelectNFTModalOpen,
    isDestinationCollectionModalOpen,
    setDestinationCollectionModalOpen,
    isCongratulationModelOpen,
    setIsCongratulationModelOpen,
    sourceChain,
    destinationChain,
    sourceCollection,
    destinationCollection,
    nft,
    allErrors,
    onNext,
    isDirty,
  };
};

import { Props } from './types';

const LinkArrow = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4514 5.14985V6.29969H12H12.5486V6.88685V7.47401H13.0743H13.6V8.0367V8.59939H14.1257H14.6514V9.16208V9.72477H15.1771H15.7029V10.2875V10.8502H9.85143H4V12V13.1498H9.85143H15.7029V13.7125V14.2752H15.1771H14.6514V14.8379V15.4006H14.1257H13.6V15.9633V16.526H13.0743H12.5486V17.1132V17.7003H12H11.4514V18.8502V20H12.5257H13.6V19.4389V18.8777L14.1371 18.864L14.6743 18.8502L14.6872 18.2752L14.7 17.7003H15.2243H15.7486V17.1376V16.5749H16.2743H16.8V16.0138V15.4526L17.3371 15.4389L17.8743 15.4251L17.8872 14.8502L17.9 14.2752H18.4243H18.9486V13.7125V13.1498H19.4743H20V12V10.8502H19.4743H18.9486V10.2875V9.72477H18.4243H17.9L17.8872 9.14985L17.8743 8.57492L17.3371 8.56113L16.8 8.54738V7.9862V7.42508H16.2743H15.7486V6.86239V6.29969H15.2243H14.7L14.6872 5.72477L14.6743 5.14985L14.1371 5.13605L13.6 5.1223V4.56113V4H12.5257H11.4514V5.14985Z"
    />
  </svg>
);

export default LinkArrow;

import { Props } from './types';

const LogoutIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="#171717"
        d="M9.375 16.875a.625.625 0 0 1-.625.625h-5a.625.625 0 0 1-.625-.625V3.125A.625.625 0 0 1 3.75 2.5h5a.625.625 0 0 1 0 1.25H4.375v12.5H8.75a.625.625 0 0 1 .625.625Zm8.567-7.317-3.125-3.125a.625.625 0 0 0-.884.884l2.058 2.058H8.75a.625.625 0 0 0 0 1.25h7.241l-2.058 2.058a.625.625 0 0 0 .884.884l3.125-3.125a.627.627 0 0 0 0-.884Z"
      />
    </svg>
  );
};

export default LogoutIcon;

import { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';

import { STEP_TWO_FIELD, TOKEN_TYPE_FIELD } from '../../formFields';
import { ExistingCollectionFormType } from '../../WizardSchema';

export const useExistingCollectionStep2 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { trigger, watch } = useFormContext<ExistingCollectionFormType>();

  const tokenType = watch(`${STEP_TWO_FIELD}.${TOKEN_TYPE_FIELD}`);

  const onNext = async () => {
    // Triggers only the validation schema for stepOne

    const isValid = await trigger(STEP_TWO_FIELD);

    if (isValid) {
      if (tokenType !== 'Normal') {
        handleActiveStep((step) => step + 1);
      } else if (tokenType === 'Normal') {
        handleActiveStep((step) => 10); // temporary
      }
    }
  };

  return { onNext, tokenType };
};

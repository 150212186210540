import { InfoIcon } from 'components/Icons';
import { Controller } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { classes } from 'utils';

interface FormTextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  placeholder?: string;
  label: string;
  shouldUnregister?: boolean;
  tooltip?: string;
  extraHeader?: React.ReactNode;
}

export const FormTextArea = ({
  name,
  label,
  disabled,
  extraHeader,
  shouldUnregister = true,
  tooltip,
  ...rest
}: FormTextAreaProps) => {
  return (
    <Controller
      name={name}
      disabled={Boolean(disabled)}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, ...restField }, fieldState: { error } }) => {
        return (
          <div className="flex flex-col">
            <ReactTooltip id={name} place="bottom" content={tooltip} />
            <label
              htmlFor={name}
              className="flex items-center justify-between mb-[9px] text-base font-medium text-lightBlack leading-[22px]"
            >
              {label}
              <div className="flex flex-row justify-between items-center gap-1">
                {extraHeader ? extraHeader : <></>}
                {tooltip && <InfoIcon className="cursor-pointer" data-tooltip-id={name}></InfoIcon>}
              </div>
            </label>
            <textarea
              id={name}
              className={classes(
                'border border-lightBlack/[0.15] rounded-3xl h-32 md:px-5 px-4 md:py-[15px] py-3 focus:outline-lightBlack focus:ring-0 resize-none',
                error ? 'border-error' : ''
              )}
              value={value || ''}
              {...rest}
              {...restField}
            />
            {error && (
              <span className="text-sm text-error flex gap-1 mt-2">
                <img src="/closeCircle.svg" alt="bodyYellowBg" />
                {error.message}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormTextArea;

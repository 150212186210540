import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Breadcrumb } from 'components';
import Form from 'components/Form';
import { useForm } from 'react-hook-form';
import { combineRoutes, routes } from 'routes/routesConst';
import { useStopReloadingPage } from 'utils';

import {
  ExistingCollectionStep1,
  ExistingCollectionStep2,
  ExistingCollectionStep3,
} from './components';
import SuccessMessage from './components/SuccessMessage';
import { ExistingCollectionFormSchema } from './WizardSchema';

export const breadcrumbData = [
  {
    path: `/${routes.nftHub}`,
    label: 'Collections',
  },
  {
    label: 'Create Collection',
    path: combineRoutes.createCollection + '?existing',
  },
];

export const ExistingCollectionForm = () => {
  const [activeStep, handleActiveStep] = useState(1);

  const form = useForm({
    resolver: zodResolver(ExistingCollectionFormSchema),
  });

  const { dirtyFields } = form.formState;

  const isDirty = Object.keys(dirtyFields).length > 0;

  useStopReloadingPage(isDirty);

  return (
    <>
      <Breadcrumb items={breadcrumbData} className="my-8" />
      <Form
        className="md:p-6 max-w-[640px] mx-auto flex flex-col h-[calc(100vh_-_196px)]"
        action="/hello"
        mode="onChange"
        form={form}
      >
        {activeStep === 1 && <ExistingCollectionStep1 handleActiveStep={handleActiveStep} />}
        {activeStep === 2 && <ExistingCollectionStep2 handleActiveStep={handleActiveStep} />}
        {activeStep === 3 && <ExistingCollectionStep3 handleActiveStep={handleActiveStep} />}
        {activeStep === 10 && <SuccessMessage />}
      </Form>
    </>
  );
};

export default ExistingCollectionForm;

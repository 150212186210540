import { GetStartedButton } from 'module/Landing/GetStartedButton';

import TypeFormButton from 'components/TypeFormButton/TypeFormButton';
import { useWindowSize } from 'hooks';
import { classes, NavbarOptions } from 'utils';

import { Header } from '../Header/Header';

const socialMediaLinks = [
  { href: 'https://t.me/pixelport_xyz', src: './telegram.svg', alt: 'telegram' },
  { href: 'https://x.com/Pixelport_xyz', src: './x.svg', alt: 'x' },
  { href: 'https://medium.com/@Pixelport_xyz', src: './medium.svg', alt: 'medium' },
];

const SocialLinks = () => {
  return (
    <>
      {socialMediaLinks.map((link, index) => (
        <a target="_blank" rel="noopener noreferrer" key={index} href={link.href}>
          <img src={link.src} className="w-[24px] md:w-[35px]" alt={link.alt} />
        </a>
      ))}
    </>
  );
};

export function Footer() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;

  const clickHandler = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
    id: string
  ): void => {
    event.preventDefault();
    const element = document.getElementById(id);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  return (
    <div
      className={classes(' bg-black p-3 px-10 md:p-4 absolute w-full bottom-0 z-[10]')}
      id="contact"
    >
      <div className="container mx-auto">
        <div className="pt-[38px] md:pt-9 md:px-8 lg:px-16 lg:pt-[58px] lg:pb-12 pb-6 flex flex-col lg:gap-8 gap-6 items-start">
          <div className="w-full flex flex-col md:flex-row justify-between text-white">
            <div className="max-w-[300px] w-full flex flex-col">
              <Header
                classNames={{
                  header:
                    'max-w-full text-[34px] md:text-[38px] leading-[72px] normal-case font-normal font-["Arcade"] font-bold uppercase',
                }}
              >
                Connect with us
              </Header>
              <p className="text-sm md:text-base leading-[20px] md:leading-[30px] normal-case font-Jakarta opacity-50">
                Contact our team anytime to learn more about how you can collaborate, create or
                explore with Pixelport.
              </p>
            </div>
            {isBelowMd && (
              <div className="flex gap-[45px] mt-[55px] md:my-8">
                <SocialLinks />
              </div>
            )}
            <div className="my-[66px] md:my-4 uppercase flex flex-col gap-[35px] font-Jakarta">
              {NavbarOptions.map(({ name, href }) => (
                <button
                  key={name}
                  className={'w-fit uppercase'}
                  onClick={(event) => clickHandler(event, href.substring(1))}
                >
                  {name}
                </button>
              ))}
            </div>
            <div className="mt-6 md:mt-4">
              <TypeFormButton formId="uvT7QzSD" as="div">
                <GetStartedButton
                  title="get early access"
                  arrowStroke="#FFF"
                  classNames={{
                    base: '!bg-white !text-black',
                    container: 'bg-black',
                    text: 'font-Jakarta font-semibold',
                  }}
                />
              </TypeFormButton>
            </div>
          </div>
          <div className="w-full flex justify-between my-8 md:my-auto">
            {!isBelowMd && (
              <div className="flex gap-[45px]">
                <SocialLinks />
              </div>
            )}
            <div className="flex flex-col xxs:flex-row items-start xxs:items-center justify-between md:justify-end w-full">
              <div className="mt-3 text-white uppercase whitespace-pre text-[31px] md:text-[44px] leading-[22px] font-['Arcade']">
                Pixelport
              </div>
              <div className="text-[#FFFFFF59] lg:ml-3 md:ml-2 text-base font-Jakarta whitespace-nowrap">
                Powered by Analog
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

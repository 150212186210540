import { Outlet } from 'react-router-dom';

import SubHeader from './../SubHeader/SubHeader';

export const PrivateLayout = () => {
  const hostName = window.location.hostname;

  if (hostName === 'pixelport.xyz') {
    return <></>;
  }

  return (
    <div className="overflow-hidden">
      <div className="bg-white min-h-screen h-auto relative">
        <div
          className="min-h-screen h-auto relative z-10 !bg-cover !bg-[84%]  md:!bg-[length:100vw_100vh]"
          style={{
            background: 'url(/newDesign/images/bgCloud.png)',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <SubHeader />
          <main className="w-full md:h-[calc(100vh-73px)] overflow-auto">
            <div className="w-full max-w-[97%] 2xl:max-w-[75%] mx-auto md:px-8 px-4 pb-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

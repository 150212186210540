import { AxiosError, AxiosResponse } from 'axios';

export const getErrorMessage = (error: AxiosError) => {
  const details = ((error as AxiosError).response as AxiosResponse).data.detail;

  if (Array.isArray(details)) {
    return `${details[0].loc[1]}: ${details[0].msg}`;
  }

  if (typeof details === 'string') return details;

  return undefined;
};

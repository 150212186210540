import { Dispatch, SetStateAction } from 'react';

import { Button, Modal } from 'components';
import { RightArrowIcon } from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { HTMLAttributes } from 'types';

export interface AddCollectionModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
}

export const CongratulationsModel = ({
  isOpen,
  className,
  setIsOpen,
  isCloseHidden,
}: AddCollectionModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{ base: 'bg-white text-black', content: 'md:w-[732px] w-[343px]' }}
      isCloseHidden={isCloseHidden}
    >
      <img
        src="/newDesign/images/Congratulations.png"
        alt="Congratulations"
        className="md:rounded-lg rounded-2xl"
      />
      <div className="uppercase md:text-[40px] text-[32px] leading-8 md:leading-10 font-secondary md:pt-8 pt-4">
        Congratulations! Your NFT is crossing the digital divide!
      </div>
      <p>Please allow a few moments for your NFT transfer to finalize.</p>
      <div className="flex flex-col md:flex-row gap-4 pt-4 md:pt-6">
        <Button
          type="button"
          variant="outlined"
          color="black"
          endIcon={
            <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-black ">
              <RightArrowIcon stroke="#fff" />
            </div>
          }
          className="!pr-1 !pl-6 !gap-5 py-6 justify-between w-full md:max-h-14 max-h-12 [&>div]:hover:text-black [&>div]:hover:invert"
          onClick={() => navigate('/')}
        >
          Back to Dashboard
        </Button>
        <Button
          type="button"
          variant="contained"
          color="black"
          endIcon={
            <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-white">
              <RightArrowIcon />
            </div>
          }
          className="!pr-1 !pl-6 !gap-5 py-6 justify-between w-full md:max-h-14 max-h-12"
          onClick={() => navigate(`/${routes.bridge}`)}
        >
          Track your NFT
        </Button>
      </div>
    </Modal>
  );
};

import { Props } from './types';

export function TransferNFTIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <mask
        id="a"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M24 .932H0v22.136h24V.932Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#000"
          d="M1.5 8.547v-.593c0-.492.453-.891 1.011-.891h15.173V5.282c0-.793 1.091-1.189 1.726-.63l3.372 2.969a.817.817 0 0 1 0 1.259l-3.372 2.968c-.632.557-1.726.167-1.726-.63v-1.78H2.51c-.558 0-1.011-.399-1.011-.89Zm20.567 5.64H6.895v-1.78c0-.792-1.09-1.191-1.727-.63l-3.372 2.968a.817.817 0 0 0 0 1.26l3.372 2.968c.633.557 1.727.166 1.727-.63v-1.781h15.172c.558 0 1.011-.399 1.011-.89v-.594c0-.492-.453-.89-1.011-.89Z"
        />
      </g>
    </svg>
  );
}

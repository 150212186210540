import { Dispatch, SetStateAction } from 'react';

import { Button, Modal } from 'components';
import { useNavigate } from 'react-router-dom';
import { combineRoutes } from 'routes/routesConst';
import { HTMLAttributes } from 'types';

interface MintNFTModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
}

export const MintNFTModal = ({
  isOpen,
  className,
  setIsOpen,
  isCloseHidden,
}: MintNFTModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{ base: 'bg-white text-black' }}
      isCloseHidden={isCloseHidden}
    >
      <div className="uppercase md:text-[40px] text-[32px] leading-10 font-secondary">
        Are you sure to mint this NFT?
      </div>
      <div className="pt-1 text-sm md:text-base text-textGray">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua.
      </div>
      <div className="flex gap-4 pt-4 flex-row md:pt-8">
        <Button
          color="black"
          variant="outlined"
          className="md:w-[320px] w-[147px] justify-center"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          color="black"
          className="md:w-[320px] w-[147px] justify-center"
          onClick={() => navigate(`${combineRoutes.createCollection}?existing`)}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export const UserGroup = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="#171717"
        d="M9.159 12.337a4.687 4.687 0 1 0-5.196 0 7.487 7.487 0 0 0-3.689 2.947.626.626 0 1 0 1.047.682 6.25 6.25 0 0 1 10.48 0 .625.625 0 0 0 1.047-.682 7.487 7.487 0 0 0-3.69-2.947Zm-6.035-3.9a3.437 3.437 0 1 1 6.875 0 3.437 3.437 0 0 1-6.875 0Zm16.417 7.711a.625.625 0 0 1-.865-.182 6.238 6.238 0 0 0-5.24-2.841.625.625 0 1 1 0-1.25 3.435 3.435 0 0 0 3.372-4.087 3.438 3.438 0 0 0-4.648-2.544.625.625 0 1 1-.465-1.16 4.688 4.688 0 0 1 4.339 8.253 7.487 7.487 0 0 1 3.689 2.947.625.625 0 0 1-.182.864Z"
      />
    </svg>
  );
};

export default UserGroup;

export const useMintNFTStep3 = () => {
  // const { watch } = useFormContext<CreateNFTFormType>();

  // const image = watch(`${STEP_ONE_FIELD}.${UPLOAD_NFT_FIELD}`);

  // const urlImage = image?.[0] && URL.createObjectURL(image?.[0]);

  const urlImage = '/demo/bald_style.png';

  return {
    urlImage,
  };
};

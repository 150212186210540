import { ReactNode } from 'react';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="">
      <main className="relative overflow-x-clip">{children}</main>
    </div>
  );
}

export default Layout;

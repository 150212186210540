import { classes } from 'utils';

export const Chip = ({
  text,
  onClick,
  active,
}: {
  text: string;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <div
      className={classes(
        'flex justify-center items-center gap-2 w-fit px-4 md:px-8 py-2 md:py-[23px] bg-grayBg cursor-pointer backdrop-blur-[16px] bg-opacity-50 hover:bg-opacity-5 rounded-full font-Jakarta text-white text-[14px] md:text-base lg:text-2xl leading-9 font-semibold group h-[40px] md:h-[60px] lg:h-[80px]',
        active ? 'bg-blackBg' : ''
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {active && (
        <img src="/icons/hand.png" className="w-[28px] md:w-[50px]" alt="hand sign" width={50} />
      )}
      {text}
    </div>
  );
};

export default Chip;

import { Suspense } from 'react';

import { Layout, PrivateLayout } from 'components';
import {
  BridgePage,
  CollectionFormPage,
  CollectionPage,
  CollectionsPage,
  CreateBridgeNFTPage,
  CreateNFTPage,
  PageNotFound,
} from 'pages';
import MintNFTPage from 'pages/Collections/MintNFT';
import LandingPage from 'pages/Landing';
import { createBrowserRouter } from 'react-router-dom';

import { combineRoutes, routes } from './routesConst';

export const router = createBrowserRouter([
  {
    path: routes.base,
    element: (
      <Suspense fallback={<p>Loading book details...</p>}>
        <Layout>
          <LandingPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    element: <PrivateLayout />,
    children: [
      {
        path: `/${routes.nftHub}`,
        element: <CollectionsPage />,
      },
      {
        path: combineRoutes.createCollection,
        element: <CollectionFormPage />,
      },
      {
        path: combineRoutes.collectionDetail,
        element: <CollectionPage />,
      },
      {
        path: combineRoutes.createNFT,
        element: <CreateNFTPage />,
      },
      {
        path: combineRoutes.mintNFT,
        element: <MintNFTPage />,
      },
      { path: `/${routes.bridge}`, element: <BridgePage /> },
      { path: combineRoutes.createBridge, element: <CreateBridgeNFTPage /> },
      // {
      //   path: `/${routes.fractionalize}`,
      //   element: <>Fractionalize</>,
      // },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />, // Catch-all route for 404
  },
]);

import { Dispatch, SetStateAction } from 'react';

import { Button, FormDropdown, Modal } from 'components';
import { routes } from 'routes/routesConst';
import { HTMLAttributes } from 'types';

import { DESTINATION_COLLECTION_FIELD, STEP_TWO_FIELD } from '../../formFields';

import { TOKEN_TYPES } from './types';

export interface AddCollectionModalProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
  handleNextClick?: () => void;
}

export const DestinationCollectionModal = ({
  isOpen,
  className,
  setIsOpen,
  isCloseHidden,
  handleNextClick,
}: AddCollectionModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      className={className}
      setIsOpen={setIsOpen}
      classNames={{ base: 'bg-white text-black', content: 'overflow-visible' }}
      isCloseHidden={isCloseHidden}
    >
      <div className="uppercase md:text-[40px] text-[28px] md:leading-10 leading-8 font-secondary">
        Select the destination
      </div>

      <FormDropdown
        options={TOKEN_TYPES as unknown as string[]}
        name={`${STEP_TWO_FIELD}.${DESTINATION_COLLECTION_FIELD}`}
        shouldUnregister={false}
        label="Select Collection"
        extraOption={{
          label: 'Add New Collection',
          redirectLink: `/${routes.nftHub}/create?new`,
        }}
      />
      <div className="flex gap-4 pt-4 flex-row md:pt-6">
        <Button
          color="black"
          variant="outlined"
          className="md:w-[320px] w-[147px] justify-center"
          onClick={() => setIsOpen(false)}
        >
          Back
        </Button>
        <Button
          color="black"
          className="md:w-[320px] w-[147px] justify-center"
          onClick={handleNextClick}
        >
          NEXT
        </Button>
      </div>
    </Modal>
  );
};

import { Pagination, PaginationProps } from 'components';
import { Header } from 'types';
import { classes } from 'utils';

interface TableProps extends PaginationProps {
  headers: Header[];
  data: any[];
}

export function DataTable({ headers, data, ...paginationProps }: TableProps) {
  return (
    <div className="rounded-[14px] overflow-hidden sm:bg-white sm:border border-neutral-300">
      <table className="min-w-full border-collapse">
        <thead className="bg-gray-50 border-b border-neutral-300 sm:table-header-group hidden">
          <tr className="hover:bg-zinc-50">
            {headers.map((header) => (
              <th
                key={header.key}
                className="text-left pl-6 pt-4 pb-3 font-normal text-neutral-900 text-opacity-70 text-xs uppercase tracking-wider"
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className="hover:bg-gray-50 border rounded-2xl sm:border-x-0 sm:border-t-0 sm:border-b  border-neutral-300 bg-white hover:bg-neutral-50 sm:table-row block p-4 mb-4"
            >
              {headers.map((header, i) => (
                <td
                  key={header.key}
                  className={classes(
                    'text-neutral-900 font-normal pb-2 sm:pl-6 sm:p-4 sm:text-base text-sm sm:table-cell flex items-center gap-2',
                    i === 0 &&
                      'pb-[11px] mb-[11px] sm:m-0 border-b sm:border-none border-neutral-300',
                    header.renderClassName
                  )}
                >
                  <span
                    className={classes(
                      'text-xs font-normal text-[#696969] sm:hidden',
                      i === 0 && 'hidden'
                    )}
                  >
                    {header.label}:{' '}
                  </span>
                  {header.render ? header.render(item[header.key], item) : item[header.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination {...paginationProps} classNames={{ container: 'sm:p-4' }} />
    </div>
  );
}

import { Props } from './types';

export function InfoIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0003 18.3334C5.39795 18.3334 1.66699 14.6024 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001C18.3337 14.6024 14.6027 18.3334 10.0003 18.3334ZM10.0003 16.6667C13.6822 16.6667 16.667 13.682 16.667 10.0001C16.667 6.31818 13.6822 3.33341 10.0003 3.33341C6.31843 3.33341 3.33366 6.31818 3.33366 10.0001C3.33366 13.682 6.31843 16.6667 10.0003 16.6667ZM9.16699 5.83341H10.8337V7.50008H9.16699V5.83341ZM9.16699 9.16675H10.8337V14.1667H9.16699V9.16675Z"
        fill="#171717"
      />
    </svg>
  );
}

import { Props } from './types';

export function Attachment(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_662_91888)">
        <path
          d="M13.3333 8.33366H14.1666V14.167H13.3333V15.0003H12.4999V15.8337H11.6666V16.667H10.8333V17.5003H9.99992V18.3337H9.16659V19.167H4.16659V18.3337H3.33325V17.5003H2.49992V16.667H1.66659V15.8337H0.833252V11.667H1.66659V10.8337H2.49992V10.0003H3.33325V9.16699H4.16659V11.667H3.33325V12.5003H2.49992V15.0003H3.33325V15.8337H4.16659V16.667H4.99992V17.5003H8.33325V16.667H9.16659V15.8337H9.99992V15.0003H10.8333V14.167H11.6666V13.3337H12.4999V9.16699H11.6666V8.33366H10.8333V7.50033H11.6666V6.66699H12.4999V7.50033H13.3333V8.33366Z"
          fill="#F7FB59"
        />
        <path
          d="M19.1666 4.16634V8.33301H18.3333V9.16634H17.4999V9.99967H16.6666V10.833H15.8333V8.33301H16.6666V7.49967H17.4999V4.99967H16.6666V4.16634H15.8333V3.33301H14.9999V2.49967H11.6666V3.33301H10.8333V4.16634H9.99992V4.99967H9.16658V5.83301H8.33325V6.66634H7.49992V10.833H8.33325V11.6663H9.16658V12.4997H8.33325V13.333H7.49992V12.4997H6.66659V11.6663H5.83325V5.83301H6.66659V4.99967H7.49992V4.16634H8.33325V3.33301H9.16658V2.49967H9.99992V1.66634H10.8333V0.833008H15.8333V1.66634H16.6666V2.49967H17.4999V3.33301H18.3333V4.16634H19.1666Z"
          fill="#F7FB59"
        />
      </g>
      <defs>
        <clipPath id="clip0_662_91888">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

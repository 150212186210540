import { Props } from './types';

export const Hamburger = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M1.75 1.5h28.5M1.75 11h28.5m-28.5 9.5h28.5"
    />
  </svg>
);
export default Hamburger;

import { Props } from './types';

export const RoundTickBlack = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#000"
      d="M11.79 0C5.381 0 .187 5.373.187 12s5.194 12 11.601 12c6.408 0 11.602-5.373 11.602-12S18.197 0 11.79 0Zm0 2.323c5.17 0 9.357 4.327 9.357 9.677 0 5.348-4.185 9.677-9.357 9.677-5.17 0-9.356-4.328-9.356-9.677 0-5.348 4.185-9.677 9.356-9.677Zm6.56 6.303-1.056-1.1a.561.561 0 0 0-.396-.172.56.56 0 0 0-.398.169l-6.613 6.785-2.796-2.917a.56.56 0 0 0-.397-.172.56.56 0 0 0-.397.169l-1.063 1.09a.548.548 0 0 0-.123.185.606.606 0 0 0 .12.637l4.246 4.428a.549.549 0 0 0 .794.003l8.075-8.284a.546.546 0 0 0 .122-.185.607.607 0 0 0-.119-.636Z"
    />
  </svg>
);

export default RoundTickBlack;

import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/'); // Redirect to the home page
  }, [navigate]);

  return null; // Optionally, you could return a loading spinner or a message here
};

export default PageNotFound;

import { Dispatch, SetStateAction } from 'react';

import { Button, FormTextInput } from 'components';

import { FRACTIONS_FIELD, STEP_THREE_FIELD } from '../../formFields';

export const ExistingCollectionStep3 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <>
      <div className="flex justify-center  flex-col h-full mb-[48px] md:mb-0 md:w-[640px] md:translate-y-[-84px]">
        <h2 className="md:text-5xl text-[40px] md:leading-10 leading-[normal] font-bold font-secondary text-lightBlack uppercase">
          Set the total number of fractions for your collection.
        </h2>
        <div className="flex flex-col gap-5 mt-6">
          <FormTextInput
            type="number"
            name={`${STEP_THREE_FIELD}.${FRACTIONS_FIELD}`}
            placeholder="ex. 2"
            label="Total Supply"
            tooltip="The total supply of fractions cannot exceed 10,000."
          />
        </div>
        <div className="flex justify-between px-[1px] gap-4 md:!mt-8 md:relative fixed bottom-0 left-0 z-10 w-full md:!p-0 !p-4">
          <Button
            onClick={() => handleActiveStep((step: number) => step - 1)}
            variant="outlined"
            color="black"
            className="justify-center w-full"
          >
            BACK
          </Button>
          <Button type="submit" color="black" variant="contained" className="justify-center w-full">
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

import { Dispatch, SetStateAction } from 'react';

import { Breadcrumb, Button, Card, FormTextInput } from 'components';
import FormDropzone from 'components/FormDropzone/FormDropzone';
import FormTextArea from 'components/FormTextArea/FormTextArea';
import { Upload } from 'components/Icons/Upload';
import toast from 'react-hot-toast';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import {
  CREATOR_NAME_FIELD,
  DESCRIPTION_FIELD,
  NFT_NAME_FIELD,
  STEP_ONE_FIELD,
} from '../../formFields';

import { useCreateNFTStep1 } from './useCreateNFTStep1';

const maxSize = 2504400;

function nameLengthValidator(file: File) {
  if (file.size > maxSize) {
    return {
      code: 'file-is-to-large',
      message: `File is larger than 2.5 MB`,
    };
  }

  return null;
}

export const CreateNFTStep1 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const { onNext, nftUpload, description } = useCreateNFTStep1({ handleActiveStep });

  return (
    <div className="lg:flex gap-10">
      <div className="lg:w-1/2">
        <Breadcrumb items={breadcrumbData} />
        <h2 className="md:text-5xl text-[40px] leading-10 font-bold font-secondary md:py-6 pt-6 pb-4 text-lightBlack uppercase">
          Create New NFT
        </h2>
        <div className="gap-5 flex flex-col">
          <FormTextInput
            name={`${STEP_ONE_FIELD}.${CREATOR_NAME_FIELD}`}
            placeholder="e.x. Ben"
            label="Creator Name"
            shouldUnregister={false}
          />
          <FormTextInput
            name={`${STEP_ONE_FIELD}.${NFT_NAME_FIELD}`}
            placeholder="SweetNFT"
            label="NFT Name"
            shouldUnregister={false}
          />
          <FormTextArea
            name={`${STEP_ONE_FIELD}.${DESCRIPTION_FIELD}`}
            placeholder="e.x. Ben"
            label="Description"
            shouldUnregister={false}
            extraHeader={
              <div className={'leading-4 text-[#999] font-normal text-sm'}>
                {description?.length || 0} / 1,000
              </div>
            }
          />
        </div>
        <div className="flex justify-between px-[1px] gap-4 !mt-[50px] lg:relative fixed bottom-0 left-0 z-10 lg:bg-transparent bg-white w-full lg:!p-0 !p-4 lg:border-0 border-t border-lightBlack/20">
          <Button color="black" variant="contained" className="justify-center w-full" type="submit">
            Submit
          </Button>
        </div>
      </div>

      <div className="lg:mb-0 mb-20 lg:mt-0 mt-6 lg:w-1/2">
        <Card
          cardTheme="light"
          className="!p-0 lg:max-w-[692px] md:h-[593px] w-full h-[293px] overflow-visible"
          classNames={{ backgroundImage: 'top-0' }}
          hideBackgroundImage
        >
          <FormDropzone
            isAddMode={false}
            name="stepOne.upload_nft"
            maxFiles={1}
            accept={{
              'image/jpeg': [],
              'image/png': [],
            }}
            validator={nameLengthValidator}
            onDropRejected={(error) => {
              toast.error(error[0].errors[0].message);
            }}
            multiple={false}
          >
            {(!nftUpload || nftUpload.length < 1) && (
              <div className="flex flex-col items-center justify-center aspect-w-1 aspect-h-1">
                <div className="md:w-36 w-[60px] md:h-36 h-[60px] rounded-full bg-black mx-auto flex items-center justify-center mb-8">
                  <Upload className="md:w-[54px] w-[23px]" />
                </div>
                <h2 className="text-center md:text-[40px] text-[32px] mb-2 text-black uppercase font-secondary leading-10">
                  Upload Your NFT
                </h2>
                <p className="text-sm text-center text-black md:text-base md:px-40">
                  Drop your digital treasure here.
                </p>
              </div>
            )}
          </FormDropzone>
        </Card>
      </div>
    </div>
  );
};

import { z } from 'zod';

import { CHAIN_FIELD, STEP_TWO_FIELD } from './formFields';

export const TOKEN_TYPES = ['Normal', 'ANLOG-404'] as const;

// const wizardOneSchema = z.object({
//   [CREATOR_NAME_FIELD]: z
//     .string({ required_error: 'Creator name is required' })
//     .min(1, 'Creator name is required'),
//   [NFT_NAME_FIELD]: z
//     .string({ required_error: 'NFT name is required' })
//     .min(1, 'NFT name is required'),
//   [DESCRIPTION_FIELD]: z
//     .string({ required_error: 'Description is required' })
//     .min(1, 'Description is required')
//     .max(1000, 'Your description is a bit long. Please keep it short and sweet!'),
//   [UPLOAD_NFT_FIELD]: z
//     .array(z.instanceof(File), { required_error: 'NFT image is required' }) // Validate that it's an array of File objects
//     .max(1, 'You can only upload one file.'),
// });

const wizardTwoSchema = z.object({
  [CHAIN_FIELD]: z.string({ required_error: 'Network is required!' }).min(1, 'Network is required'),
});

export const CreateNFTFormSchema = z.object({
  // [STEP_ONE_FIELD]: wizardOneSchema,
  [STEP_TWO_FIELD]: wizardTwoSchema,
});

export type CreateNFTFormType = z.infer<typeof CreateNFTFormSchema>;

import { ReactNode } from 'react';

import { ClassNames } from 'types';
import { classes } from 'utils';
import './card.css';

export type CardProps = {
  cardTheme?: 'light' | 'dark';
  onClick?: () => void;
  children?: ReactNode | string;
  className?: string;
  classNames?: ClassNames<'backgroundImage' | 'imgCard'>;
  backgroundImage?: string;
  featureImage?: string;
  hideBackgroundImage?: boolean;
};

export const Card = ({
  cardTheme = 'light',
  onClick,
  children,
  className,
  classNames,
  backgroundImage = '/cardPixelBg.svg',
  featureImage,
  hideBackgroundImage = false,
}: CardProps) => {
  const cardClass =
    cardTheme === 'dark' ? 'bg-lightBlack relative z-[1]' : 'p-5 bg-white card_border';

  return (
    <div
      className={classes('rounded-2xl overflow-hidden', cardClass, className)}
      onClick={onClick}
      role="presentation"
    >
      {featureImage && <img src={featureImage} className="w-full" />}
      <div className={classes('relative h-full', classNames?.imgCard)}>
        {cardTheme === 'dark' && !hideBackgroundImage && (
          <img
            src={backgroundImage}
            alt="cardPixelBg"
            className={classes(
              'w-full absolute -top-5 left-0 rounded-2xl -z-[1]',
              classNames?.backgroundImage
            )}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Card;

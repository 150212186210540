import { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { classes } from 'utils';

interface DownloadCardProps {
  icon?: ReactNode;
  content: string | ReactNode;
  subtext: string;
  linkText?: string;
  startButton?: ReactNode;
  endButton?: ReactNode;
  className?: string;
}

export const DownloadCard = ({
  icon,
  content,
  subtext,
  linkText,
  startButton,
  endButton,
  className,
}: DownloadCardProps) => {
  return (
    <div
      className={classes(
        'flex flex-row md:items-center justify-start gap-4 p-4 text-white bg-lightBlack md:p-5 w-full rounded-2xl xl:rounded-2xl',
        className
      )}
    >
      {startButton}
      <div className="flex items-center justify-center md:w-[50px] w-[50px] md:h-[50px] h-[50px] rounded-full bg-[#ffffff10]  flex-shrink-0">
        {icon}
      </div>
      <div className="flex flex-col md:flex-row md:items-center items-start md:justify-between w-full gap-4">
        <div className="leading-normal">
          <div className="uppercase font-primary md:text-[24px] text-2xl text-white">{content}</div>
          <span className="md:text-base text-sm leading-[22px]">
            <div className="text-white/[0.72]">
              {subtext}
              <Link
                to="https://pixelport.xyz/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-yellow"
              >
                {linkText}
              </Link>
            </div>
          </span>
        </div>
        {endButton}
      </div>
    </div>
  );
};

export default DownloadCard;

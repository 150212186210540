export const getAllErrorMessages = (errorsObject: any) => {
  const errorMessages: string[] = [];

  const extractMessages = (errObj: any) => {
    for (const key in errObj) {
      if (errObj[key]?.message) {
        errorMessages.push(errObj[key].message);
      } else if (typeof errObj[key] === 'object') {
        extractMessages(errObj[key]);
      }
    }
  };

  extractMessages(errorsObject);

  return errorMessages.join(', ');
};

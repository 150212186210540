import { Props } from './types';

export const HorizontalMenu = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <circle cx={4} cy={10} r={1.5} fill="#171717" />
    <circle cx={10} cy={10} r={1.5} fill="#171717" />
    <circle cx={16} cy={10} r={1.5} fill="#171717" />
  </svg>
);

export default HorizontalMenu;

import { Props } from './types';

export function Upload(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 35.8868V44.7763H5.12554H6.25109V45.8882V47H26.5H46.7489V45.8882V44.7763H47.8745H49V35.8868V26.9973H46.7489H44.4978V34.7749V42.5526H26.5054H8.51305V34.7749V26.9973H6.25653H4V35.8868Z"
        fill="#fff"
      />
      <path
        d="M28.777 20.3368L28.777 28.1145L28.777 35.8921L26.5259 35.8921L24.2748 35.8921L24.2748 28.1145L24.2748 20.3368L18.6498 20.3395L14.1504 20.3368L14.1476 19.2277L14.1448 18.1185L15.2704 18.1185L16.396 18.1185L16.396 17.0066L16.396 15.8948L17.5215 15.8948L18.6471 15.8948L18.6471 14.7829L18.6471 13.6711L19.7726 13.6711L20.8982 13.6711L20.8982 12.5592L20.8982 11.4474L22.0237 11.4474L23.1493 11.4474L23.1493 10.3355L23.1493 9.22364L24.2748 9.22364L25.4003 9.22364L25.4003 8.11178L25.4003 6.99992L26.5259 6.99992L27.6514 6.99992L27.6514 8.11178L27.6514 9.22364L28.777 9.22364L29.9025 9.22364L29.9025 10.3355L29.9025 11.4474L31.0281 11.4474L32.1536 11.4474L32.1536 12.5592L32.1536 13.6711L33.2792 13.6711L34.4047 13.6711L34.4047 14.7829L34.4047 15.8948L35.5302 15.8948L36.6558 15.8948L36.6558 17.0066L36.6558 18.1185L37.7759 18.1185L38.896 18.1185L38.896 19.2304L38.896 20.3422L33.2737 20.3422L28.777 20.3368Z"
        fill="#fff"
      />
    </svg>
  );
}

import { Dispatch, SetStateAction } from 'react';

import { Breadcrumb, Button, Card } from 'components';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import { useMintNFTStep3 } from './useMintNFTStep3';

export const CreateNFTStep3 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const { urlImage } = useMintNFTStep3();

  return (
    <div className="md:flex flex-col items-start">
      <Breadcrumb items={[breadcrumbData[0]]} />
      <div className="md:w-[640px] mx-auto rounded-2xl overflow-hidden lg:mt-0 mt-5">
        <img src={urlImage} alt="Fractionalize_4" className="w-full" />
        <Card cardTheme="dark" className="rounded-tl-none rounded-tr-none" hideBackgroundImage>
          <div className="md:p-5 p-4">
            <div className="md:text-5xl text-[40px] md:leading-10 leading-[normal] font-bold font-secondary uppercase text-white">
              NFT Name
            </div>
            <div className="flex justify-between items-center text-white md:text-base text-sm font-medium py-2">
              <div>Owned by 0x7499...34d5</div>
              <div className="text-[#999999]">Collection Name</div>
            </div>
            <div className="text-[#BEBEBE] md:text-base text-sm font-normal">
              Mint your NFTs and start your digital collection in just a few clicks. Simple, fast,
              and ready to share!
            </div>
            <Button
              variant="outlined"
              color="white"
              className="w-full border-white/50 capitalize !max-h-14 md:mt-6 mt-4 !pl-4 !pr-2 justify-between md:!py-3 !py-2"
            >
              View on
              <div className="flex gap-3">
                <img
                  src="/icons/chain/ethereum.svg"
                  alt="binance"
                  width={40}
                  height={40}
                  className="md:w-10 w-8 md:h-10 h-8"
                />
                <img
                  src="/icons/chain/polygon.svg"
                  alt="binance"
                  width={40}
                  height={40}
                  className="md:w-10 w-8 md:h-10 h-8"
                />
                <img
                  src="/icons/chain/binance.svg"
                  alt="binance"
                  width={40}
                  height={40}
                  className="md:w-10 w-8 md:h-10 h-8"
                />
              </div>
            </Button>
            <Button
              variant="contained"
              color="white"
              className="w-full justify-center !max-h-14 md:mt-6 mt-4"
            >
              Return to Collection
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

import { Dispatch, SetStateAction } from 'react';

import { Breadcrumb, Button } from 'components';
import { RightArrowIcon } from 'components/Icons';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import Summary from '../Summary';

import { CongratulationsModel } from './CongratulationsModel';
import { DestinationCollectionModal } from './DestinationCollectionModal';
import { SelectNFTModal } from './SelectNFTModal';
import { SourceCollectionModal } from './SourceCollectionModal';
import { useCreateBridgeNFTStep2 } from './useCreateBridgeNFTStep2';

export const CreateBridgeNFTStep2 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const {
    isSelectCollectionModalOpen,
    setSelectCollectionModalOpen,
    isSelectNFTModalOpen,
    setIsSelectNFTModalOpen,
    isDestinationCollectionModalOpen,
    setDestinationCollectionModalOpen,
    isCongratulationModelOpen,
    setIsCongratulationModelOpen,
    sourceChain,
    destinationChain,
    sourceCollection,
    destinationCollection,
    nft,
    allErrors,
    onNext,
    isDirty,
  } = useCreateBridgeNFTStep2();

  return (
    <div>
      <Breadcrumb items={breadcrumbData} className="md:py-8 py-4" />
      <div>
        <div className="md:flex justify-center gap-10 md:pt-6 pt-8 w-full mx-auto">
          <div className="w-full md:w-[678px]">
            <h2 className="md:text-5xl text-[40px]  uppercase leading-10 font-bold font-secondary">
              Select Collections
            </h2>
            <div className="md:pt-0 pt-1 md:pb-6 pb-4 text-textGray">
              Teleport your NFTs to a new chain.
            </div>

            <div className="border border-borderLight rounded-2xl bg-white">
              <div className="flex justify-between md:p-5 p-4 border-b border-borderLight">
                <div className="flex gap-1.5 md:gap-3.5">
                  <div className="bg-black min-h-[40px] min-w-[40px] h-[40px] md:h-[40px] rounded-lg flex justify-center items-center">
                    <img
                      src="/sourceIcon.svg"
                      alt="sourceIcon"
                      className="md:w-[22px] md:h-[22px] w-[22px] h-[22px] m-[3px] md:m-[9px]"
                    />
                  </div>
                  <div>
                    <h2 className="font-primary md:text-[20px] text-[16px] leading-7 font-semibold text-lightBlack uppercase">
                      Select a Collection
                    </h2>
                    <p className="text-textGray md:text-sm text-xs">
                      This collection contains the NFT you want to bridge.
                    </p>
                  </div>
                </div>
                <Button
                  type="button"
                  className="bg-transparent px-0 py-0"
                  onClick={() => {
                    setSelectCollectionModalOpen(true);
                  }}
                >
                  <SourceCollectionModal
                    isOpen={isSelectCollectionModalOpen}
                    setIsOpen={setSelectCollectionModalOpen}
                    isCloseHidden={true}
                    handleNextClick={() => {
                      setSelectCollectionModalOpen(false);
                      setIsSelectNFTModalOpen(true);
                    }}
                  />
                  <SelectNFTModal
                    isOpen={isSelectNFTModalOpen}
                    setIsOpen={setIsSelectNFTModalOpen}
                    isCloseHidden={true}
                    handleSaveClick={() => setIsSelectNFTModalOpen(false)}
                  />
                  <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-black">
                    <RightArrowIcon stroke="#fff" />
                  </div>
                </Button>
              </div>
              <div className="flex justify-between gap-2 md:p-5 p-4 py-3">
                <div className="flex gap-2">
                  {sourceChain?.icon && sourceChain?.icon}
                  <div>{sourceChain?.label && sourceChain?.label}</div>
                </div>
                <div className="flex gap-2">
                  {nft && (
                    <>
                      <div>{sourceCollection}</div>
                      <img src={nft?.featureImage} width={24} height={24} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="border border-borderLight rounded-2xl bg-white mt-4">
              <div className="flex justify-between p-5 border-b border-borderLight">
                <div className="flex gap-1.5 md:gap-3.5">
                  <div className="bg-black min-h-[40px] min-w-[40px] h-[40px] md:h-[40px] rounded-lg flex justify-center items-center">
                    <img
                      src="/destinationIcon.svg"
                      alt="sourceIcon"
                      className="md:w-[22px] md:h-[22px] w-[22px] h-[22px] m-[3px] md:m-[9px]"
                    />
                  </div>
                  <div>
                    <h2 className="font-primary md:text-[20px] text-[16px] leading-7 font-semibold text-lightBlack uppercase">
                      Destination Collection
                    </h2>
                    <p className="text-textGray md:text-sm text-xs">
                      Choose a target collection or enter a wallet address.
                    </p>
                  </div>
                </div>
                <Button
                  type="button"
                  className="bg-transparent px-0 py-0"
                  onClick={() => {
                    setDestinationCollectionModalOpen(true);
                  }}
                >
                  <DestinationCollectionModal
                    isOpen={isDestinationCollectionModalOpen}
                    setIsOpen={setDestinationCollectionModalOpen}
                    isCloseHidden={true}
                    handleNextClick={() => setDestinationCollectionModalOpen(false)}
                  />
                  <div className="flex items-center justify-center md:w-12 w-10 md:h-12 h-10 rounded-full bg-black">
                    <RightArrowIcon stroke="#fff" />
                  </div>
                </Button>
              </div>
              <div className="flex gap-2 justify-between md:p-5 p-4 py-3">
                <div className="flex gap-2">
                  {destinationChain?.icon && destinationChain.icon}
                  <div>{destinationChain?.label && destinationChain.label}</div>
                </div>
                {destinationCollection && destinationCollection}
              </div>
            </div>
            {allErrors && isDirty && (
              <span className="text-sm text-error flex gap-1 mt-2">
                <img src="/closeCircle.svg" alt="bodyYellowBg" />
                {allErrors}
              </span>
            )}
            <div className="flex justify-between px-[10px] gap-4 md:mt-8 mt-6">
              <Button
                color="black"
                variant="outlined"
                className="justify-center w-full"
                type="button"
                onClick={() => handleActiveStep((prevStep) => prevStep - 1)}
              >
                BACK
              </Button>
              <Button
                color="black"
                variant="contained"
                className="justify-center w-full"
                onClick={() => onNext()}
              >
                NEXT
              </Button>
            </div>
          </div>
          <Summary />
          <CongratulationsModel
            isOpen={isCongratulationModelOpen}
            setIsOpen={setIsCongratulationModelOpen}
          />
        </div>
      </div>
    </div>
  );
};

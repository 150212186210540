import { Props } from './types';

export const TurnOff = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#000"
      d="M18.967 2.577C22.017 4.719 24 8.223 24 12.19 24 18.705 18.639 23.986 12.024 24 5.419 24.014.01 18.715 0 12.21-.005 8.242 1.98 4.73 5.022 2.58c.567-.395 1.356-.229 1.694.367l.765 1.338c.137.242.18.53.122.805-.06.274-.218.515-.442.671-2.007 1.466-3.29 3.79-3.29 6.423C3.866 16.582 7.475 20.19 12 20.19c4.433 0 8.158-3.533 8.129-8.052-.015-2.467-1.195-4.847-3.295-6.381a1.097 1.097 0 0 1-.438-.67 1.137 1.137 0 0 1 .123-.8l.765-1.34a1.169 1.169 0 0 1 1.683-.37Zm-5.031 9.995V1.143A1.15 1.15 0 0 0 12.774 0h-1.548a1.15 1.15 0 0 0-1.162 1.143v11.429a1.15 1.15 0 0 0 1.162 1.142h1.548a1.15 1.15 0 0 0 1.162-1.142Z"
    />
  </svg>
);
export default TurnOff;

import { Props } from './types';

export function PixelportRightArrow(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M14.8723 9.11548L14.8723 6.98779L12.7446 6.98779L12.7446 9.11548L2 9.11549L2 11.2432L12.7446 11.2432L12.7446 13.3709L14.8723 13.3709L14.8723 11.2432L17 11.2432L17 9.11548L14.8723 9.11548Z"
        fill="#171717"
      />
      <path
        d="M10.259 5.12793L10.259 7.25562L12.3867 7.25562L12.3867 5.12793L10.259 5.12793Z"
        fill="#171717"
      />
      <path d="M8.13696 3L8.13696 5.12769L10.2647 5.12769L10.2647 3L8.13696 3Z" fill="#171717" />
      <path
        d="M10.259 15.7644L12.3867 15.7644L12.3867 13.6367L10.259 13.6367L10.259 15.7644Z"
        fill="#171717"
      />
      <path
        d="M8.13696 17.8923L10.2647 17.8923L10.2647 15.7646L8.13696 15.7646L8.13696 17.8923Z"
        fill="#171717"
      />
    </svg>
  );
}

import { Props } from './types';

export function SwapArrow(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2813 14.0543L10.5627 14.0543L10.5627 14.5L10.5627 14.9457L10.1957 14.9457L9.82875 14.9457L9.82875 15.3729L9.82875 15.8L9.47706 15.8L9.12538 15.8L9.12538 16.2271L9.12538 16.6543L8.7737 16.6543L8.42202 16.6543L8.42202 17.0814L8.42202 17.5086L8.07034 17.5086L7.71865 17.5086L7.71865 12.7543L7.71865 4L7 4L6.28135 4L6.28134 12.7543L6.28134 17.5086L5.92966 17.5086L5.57798 17.5086L5.57798 17.0814L5.57798 16.6543L5.2263 16.6543L4.87462 16.6543L4.87462 16.2271L4.87462 15.8L4.52294 15.8L4.17125 15.8L4.17125 15.3729L4.17125 14.9457L3.80428 14.9457L3.43731 14.9457L3.43731 14.5L3.43731 14.0543L2.71865 14.0543L2 14.0543L2 14.9271L2 15.8L2.3507 15.8L2.70144 15.8L2.71003 16.2364L2.71865 16.6729L3.07798 16.6833L3.43731 16.6938L3.43731 17.1198L3.43731 17.5457L3.78899 17.5457L4.14067 17.5457L4.14067 17.9729L4.14067 18.4L4.49137 18.4L4.84211 18.4L4.8507 18.8364L4.85933 19.2729L5.21865 19.2833L5.57798 19.2938L5.57798 19.7198L5.57798 20.1457L5.92966 20.1457L6.28134 20.1457L6.28134 20.5729L6.28134 21L7 21L7.71865 21L7.71865 20.5729L7.71865 20.1457L8.07034 20.1457L8.42202 20.1457L8.42202 19.7198L8.42202 19.2938L8.78134 19.2833L9.14067 19.2729L9.1493 18.8364L9.15789 18.4L9.50862 18.4L9.85933 18.4L9.85933 17.9729L9.85933 17.5457L10.211 17.5457L10.5627 17.5457L10.5627 17.1198L10.5627 16.6938L10.922 16.6833L11.2813 16.6729L11.29 16.2364L11.2986 15.8L11.6493 15.8L12 15.8L12 14.9271L12 14.0543L11.2813 14.0543Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7187 10.9457L13.4373 10.9457L13.4373 10.5L13.4373 10.0543L13.8043 10.0543L14.1713 10.0543L14.1713 9.62714L14.1713 9.2L14.5229 9.2L14.8746 9.2L14.8746 8.77286L14.8746 8.34571L15.2263 8.34571L15.578 8.34572L15.578 7.91857L15.578 7.49143L15.9297 7.49143L16.2813 7.49143L16.2813 12.2457L16.2813 21L17 21L17.7187 21L17.7187 12.2457L17.7187 7.49143L18.0703 7.49143L18.422 7.49143L18.422 7.91857L18.422 8.34572L18.7737 8.34572L19.1254 8.34572L19.1254 8.77286L19.1254 9.2L19.4771 9.2L19.8287 9.2L19.8287 9.62714L19.8287 10.0543L20.1957 10.0543L20.5627 10.0543L20.5627 10.5L20.5627 10.9457L21.2813 10.9457L22 10.9457L22 10.0729L22 9.2L21.6493 9.2L21.2986 9.2L21.29 8.76357L21.2813 8.32714L20.922 8.31667L20.5627 8.30623L20.5627 7.88024L20.5627 7.45429L20.211 7.45429L19.8593 7.45429L19.8593 7.02714L19.8593 6.6L19.5086 6.6L19.1579 6.6L19.1493 6.16357L19.1407 5.72714L18.7813 5.71667L18.422 5.70623L18.422 5.28024L18.422 4.85429L18.0703 4.85429L17.7187 4.85429L17.7187 4.42714L17.7187 4L17 4L16.2813 4L16.2813 4.42714L16.2813 4.85429L15.9297 4.85429L15.578 4.85429L15.578 5.28024L15.578 5.70623L15.2187 5.71667L14.8593 5.72714L14.8507 6.16357L14.8421 6.6L14.4914 6.6L14.1407 6.6L14.1407 7.02714L14.1407 7.45429L13.789 7.45429L13.4373 7.45429L13.4373 7.88024L13.4373 8.30623L13.078 8.31667L12.7187 8.32714L12.71 8.76357L12.7014 9.2L12.3507 9.2L12 9.2L12 10.0729L12 10.9457L12.7187 10.9457Z"
      />
    </svg>
  );
}

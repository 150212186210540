import { Disclosure, DisclosurePanel } from '@headlessui/react';
import { InfoIcon } from 'components/Icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const Summary = () => {
  return (
    <div className="md:w-1/2 md:mt-0 mt-6 md:max-w-[278px] mb-[100px] md:mb-0">
      <div className="w-full rounded-2xl bg-[#F8F8F8] overflow-hidden">
        <Disclosure as="div" defaultOpen={true} className="">
          <div className="flex items-center justify-between w-full group md:px-8 px-4 md:py-6 py-3.5">
            <span className="md:text-2xl text-lg font-medium text-black group-data-[hover]:text-black/80">
              Summary
            </span>
          </div>
          <DisclosurePanel className="flex flex-col gap-2 md:px-6 px-4 md:py-4 py-4 md:pb-6 border-t border-[#E6E6E6]">
            <div className="flex justify-between md:text-lg text-sm">
              <div className="text-black/50 flex items-center gap-1">
                Gas Fee
                <>
                  <InfoIcon className="cursor-pointer" data-tooltip-id="test">
                    "Gas Fee tooltip"
                  </InfoIcon>

                  <ReactTooltip
                    id="test"
                    place="bottom"
                    content="test"
                    className="!rounded-xl !bg-lightBlack !text-xs !font-medium"
                  />
                </>
              </div>

              <div>$20.00</div>
            </div>
            <div className="flex justify-between md:text-lg text-base">
              <div className="text-black/50">Network Fee</div>
              <div>$20.00</div>
            </div>
          </DisclosurePanel>
        </Disclosure>
        <Disclosure as="div" className="md:px-8 px-4 md:py-[25px] py-4 bg-[#F2F2F2] !border-t-0">
          <div className="md:text-2xl text-lg font-medium text-black group-data-[hover]:text-black/80 flex justify-between">
            <span className="md:text-xl text-base">Total</span>
            <span>$00.00</span>
          </div>
        </Disclosure>
      </div>
    </div>
  );
};

export default Summary;

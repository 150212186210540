import { useState } from 'react';

import { Chip, Header } from 'components';
import { useWindowSize } from 'hooks';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import AdvantageTabs from './components/AdvantageTabs';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import { useCaseData } from './utils';

export const slides = (isBelowMd: boolean, activeUseCase: number) => {
  return useCaseData[activeUseCase].data.map(({ heading, menu }, index) => {
    const slideContent = (
      <div
        className="md:px-0 sticky top-[10%] z-10"
        style={{
          top: isBelowMd ? `${10 + index * 84}px` : `${10 + index * 100}px`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
        }}
        key={index}
      >
        <div
          className="border border-black/20 rounded-[16px] py-6 md:py-9 px-[30px] lg:px-28 bg-white bg-opacity-70 backdrop-blur-[10px] h-[520px] md:h-auto min-h-[520px] md:min-h-fit mx-2"
          key={`advantage-chunk-${index}`}
        >
          <img
            src="/newDesign/cardCorner.svg"
            alt="card-corner"
            className="absolute top-[-2px] -right-[2px] hidden md:block h-[80px] lg:h-auto"
          />
          <AdvantageTabs menu={menu} text={heading} key={activeUseCase} />
        </div>
      </div>
    );

    return isBelowMd ? <SwiperSlide key={index}>{slideContent}</SwiperSlide> : slideContent;
  });
};

export function UseCases() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;
  const [activeUseCase, setActiveUseCase] = useState(0);

  return (
    <div
      className="max-w-[1400px] mx-auto pt-[50%] sm:pt-[50%] md:pt-[18%] xl:pt-[14%] xl:mt-[-300px] 2xl:mt-[-200px]"
      id="use-cases"
    >
      <Header
        tag={'h2'}
        classNames={{
          header:
            'uppercase text-[40px] xxs:text-[48px] md:text-[64px] 2xl:text-[90px] leading-[39px] md:leading-[72px] font-["Arcade"] pixelport-text-shadow-dark text-white text-center px-0 xl:px-56 z-[5] relative',
        }}
      >
        Get ready to discover unbounded use cases
      </Header>
      <div>
        <div className="flex flex-wrap justify-center gap-[11px] md:gap-[25px] mt-[32px] md:mt-[53px] px-4">
          {useCaseData.map(({ key }, index) => {
            return (
              <Chip
                key={index}
                text={key}
                onClick={() => setActiveUseCase(index)}
                active={index === activeUseCase}
              />
            );
          })}
        </div>
        <div className="flex flex-col gap-10 pt-[70px] mx-2 xxs:mx-5 md:pb-[150px]">
          {isBelowMd && (
            <Swiper
              scrollbar={{
                hide: false,
                draggable: true, // Enable dragging
                snapOnRelease: true, // Scroll to nearest slide on release
              }}
              modules={[Scrollbar]}
              className="mySwiper overflow-visible"
            >
              {slides(isBelowMd, activeUseCase)}
            </Swiper>
          )}
          {!isBelowMd && slides(isBelowMd, activeUseCase)}
          <div className="md:py-2"></div>
        </div>
      </div>
    </div>
  );
}

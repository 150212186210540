import { useSearchParams } from 'react-router-dom';

import { ExistingCollectionForm, NewCollectionForm } from './components';

export const CollectionForm = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.has('existing')) {
    return <ExistingCollectionForm />;
  }

  return <NewCollectionForm />;
};

export default CollectionForm;

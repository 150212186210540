import { Dispatch, SetStateAction } from 'react';

import { Button, FormTextInput } from 'components';

import { ERC721_address_FIELD, STEP_ONE_FIELD } from '../../formFields';

import useExistingCollectionStep1 from './useExistingCollectionStep1';

export const ExistingCollectionStep1 = ({
  handleActiveStep,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const { navigate, onNext } = useExistingCollectionStep1({ handleActiveStep });

  return (
    <>
      <div className="flex justify-center flex-col h-full mb-[48px] md:mb-0 md:w-[640px] md:translate-y-[-84px]">
        <h2 className="md:text-5xl text-[40px] md:leading-10 leading-[normal] font-bold font-secondary text-lightBlack">
          COLLECTION DETAILS
        </h2>
        <div className="flex flex-col gap-5 mt-6">
          <FormTextInput
            name={`${STEP_ONE_FIELD}.${ERC721_address_FIELD}`}
            placeholder="ex. 1da2ae3rg1ada31dghkjkl2"
            label="ERC721 address"
            shouldUnregister={false}
          />
        </div>
        <div className="flex justify-between px-[1px] gap-4 md:!mt-8 md:relative fixed bottom-0 left-0 z-10 w-full md:!p-0 !p-4">
          <Button
            color="black"
            variant="outlined"
            className="justify-center w-full"
            onClick={() => navigate(-1)}
          >
            BACK
          </Button>
          <Button
            color="black"
            variant="contained"
            className="justify-center w-full"
            onClick={onNext}
          >
            NEXT
          </Button>
        </div>
      </div>
    </>
  );
};

import { Props } from './types';

export function AddIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.574967 0.33632V0.67264H0.287483H0V4.65106V8.62948H0.287483H0.574967V8.9576V9.28571H4.64286H8.71075V8.9576V8.62948H8.99823H9.28571V4.69207V0.754669H8.99823H8.71075V0.377335V0H4.64286H0.574967V0.33632Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.574967 11.0507V11.387H0.287483H0V15.3654V19.3438H0.287483H0.574967V19.672V20.0001H4.64286H8.71075V19.672V19.3438H8.99823H9.28571V15.4064V11.469H8.99823H8.71075V11.0917V10.7144H4.64286H0.574967V11.0507Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8977 14.8979V12.4388H15.1841V12.1428H15.5297V12.4388H15.8176L15.816 14.8979H18.2752V15.2227H18.5711V15.5491H18.2752V15.8163H15.816V18.2754H15.5297V18.5714H15.1841V18.2754H14.8977V15.8163H12.4194V15.5491H12.1426V15.2227H12.4194V14.8979H14.8977Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2888 0.33632V0.67264H11.0014H10.7139V4.65106V8.62948H11.0014H11.2888V8.9576V9.28571H15.3567H19.4246V8.9576V8.62948H19.7121H19.9996V4.69207V0.754669H19.7121H19.4246V0.377335V0H15.3567H11.2888V0.33632Z"
      />
    </svg>
  );
}

import { Dispatch, SetStateAction } from 'react';

import { Breadcrumb, Button, FormDropdown } from 'components';
import { SwapArrow } from 'components/Icons/SwapArrow';
import { BreadcrumbItem } from 'utils/breadcrumbTypes';

import { DESTINATION_FIELD, SOURCE_FIELD, STEP_ONE_FIELD } from '../../formFields';

import { useBridgeNFTStep1 } from './useCreateBridgeNFTStep1';

export const CHAIN_TYPES = ['Ethereum', 'Astar'] as const;

const options = [
  {
    label: 'polygon',
    value: 'polygon',
    icon: <img src="/icons/chain/polygon.svg" alt="baseIcon" width={24} />,
  },
  {
    label: 'Ethereum',
    value: 'ethereum',
    icon: <img src="/icons/chain/ethereum.svg" alt="baseIcon" width={24} />,
  },
  {
    label: 'binance',
    value: 'binance',
    icon: <img src="/icons/chain/binance.svg" alt="baseIcon" width={24} />,
  },
  {
    label: 'Astar',
    value: 'astar',
    icon: <img src="/icons/chain/astar.svg" alt="baseIcon" width={24} />,
  },
  {
    label: 'Avalanche',
    value: 'avalanche',
    icon: <img src="/icons/chain/avalanche.svg" alt="baseIcon" width={24} />,
  },
];

export const CreateBridgeNFTStep1 = ({
  handleActiveStep,
  breadcrumbData,
}: {
  handleActiveStep: Dispatch<SetStateAction<number>>;
  breadcrumbData: BreadcrumbItem[];
}) => {
  const { watch, setValue, onNext, allErrors } = useBridgeNFTStep1({ handleActiveStep });

  const sourceValue = watch(`${STEP_ONE_FIELD}.${SOURCE_FIELD}`);
  const destinationValue = watch(`${STEP_ONE_FIELD}.${DESTINATION_FIELD}`);

  const handleSwap = () => {
    setValue(`${STEP_ONE_FIELD}.${SOURCE_FIELD}`, destinationValue);
    setValue(`${STEP_ONE_FIELD}.${DESTINATION_FIELD}`, sourceValue);
  };

  return (
    <>
      <Breadcrumb items={breadcrumbData} className="md:py-8 py-4" />
      <div className="md:flex md:items-center gap-10 h-[calc(100vh_-_196px)] md:translate-y-[-42px]">
        <div className="md:mb-0 md:w-[640px] mx-auto">
          <h2 className="md:text-5xl text-[40px] uppercase leading-10 font-bold font-secondary">
            bridge NFT
          </h2>
          <p className="md:pb-6 pb-4 text-textGray">Teleport your NFTs to a new chain.</p>
          <div className="relative">
            <FormDropdown
              hideError
              shouldUnregister={false}
              options={options}
              name={`${STEP_ONE_FIELD}.${SOURCE_FIELD}`}
              placeholder="Source"
              mobileLabel="Blockchain"
              classNames={{
                label: 'hidden',
                dropBorder: 'rounded-tr-2xl rounded-tl-2xl rounded-br-none rounded-bl-none',
              }}
            />
            <div className="bg-black rounded-full flex justify-center items-center w-14 h-14 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Button className="bg-transparent" onClick={handleSwap} type="button">
                <SwapArrow fill="#FFF" />
              </Button>
            </div>
            <FormDropdown
              hideError
              shouldUnregister={false}
              options={options}
              name={`${STEP_ONE_FIELD}.${DESTINATION_FIELD}`}
              placeholder="Destination"
              mobileLabel="Blockchain"
              classNames={{
                label: 'hidden',
                dropBorder:
                  'rounded-br-2xl rounded-bl-2xl rounded-tr-none rounded-tl-none border-t-0',
              }}
            />
          </div>
          {allErrors && (
            <span className="text-sm text-error flex gap-1 mt-2">
              <img src="/closeCircle.svg" alt="bodyYellowBg" />
              {allErrors}
            </span>
          )}
          <div className="flex justify-between px-[1px] gap-4 md:mt-[50px] mt-6 md:relative">
            <Button
              color="black"
              variant="contained"
              className="justify-center w-full"
              onClick={onNext}
            >
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

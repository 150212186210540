import { classes } from 'utils';

import { Loader } from './../Icons/Loader';
import { tvButtonStyle } from './tvButton.style';
import { Props$Button } from './types';

export const Button = (props: Props$Button) => {
  const {
    isLoading,
    className,
    disabled,
    size,
    variant,
    color,
    children,
    startIcon,
    endIcon,
    ...rest
  } = props;

  const tvButton = tvButtonStyle({
    size,
    variant,
    color,
    disabled,
    isLoading,
    startIcon: !!startIcon,
    endIcon: !!endIcon,
  });

  return (
    <button
      className={classes(tvButton, `flex items-center gap-3`, className)}
      disabled={disabled}
      {...rest}
    >
      {isLoading && (
        <>
          <div className="flex flex-row justify-center items-center gap-2">
            <Loader />
            <span>Loading...</span>
          </div>
        </>
      )}
      {!isLoading && startIcon}
      {!isLoading && children}
      {!isLoading && endIcon}
    </button>
  );
};

export default Button;

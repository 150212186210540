import { Dispatch, SetStateAction, useCallback } from 'react';

import { Dialog } from '@headlessui/react';
import { ClassNames, HTMLAttributes } from 'types';
import { classes } from 'utils';

import { CardPlus } from './../Icons/CardPlus';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  classNames?: ClassNames<'closeIcon' | 'content' | 'modalBase' | 'cardImage'>;
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
}

export const Modal = ({
  children,
  className,
  classNames,
  isOpen,
  setIsOpen,
  isCloseInside = false,
  isCloseHidden,
}: ModalProps) => {
  const onClose = useCallback(() => !!setIsOpen && setIsOpen(false), [setIsOpen]);

  return (
    <Dialog
      open={isOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={onClose}
      className={classes('relative z-[100]')}
    >
      <div className="fixed inset-0 bg-black/70 backdrop-blur-md" aria-hidden="true" />
      <div
        className={classes(
          'fixed inset-0 flex items-center justify-center p-4',
          classNames?.modalBase
        )}
        role="presentation"
        onClick={onClose}
      >
        <div
          className={classes(
            'relative mx-auto max-w-min flex flex-col text-white bg-primary-background bg-no-repeat rounded-2xl [box-shadow:0px_4px_10px_0px_rgba(255,_255,_255,_0.03)]',
            classNames?.base,
            className
          )}
          role="presentation"
          onClick={(e) => e.stopPropagation()}
        >
          {!!setIsOpen && !isCloseHidden && (
            <button
              className={classes(
                'absolute [outline:none]',
                isCloseInside
                  ? 'right-[25px] md:top-[25px] top-[19px]'
                  : 'md:top-6 top-4 md:right-6 right-4 flex h-8 w-8 rounded-full bg-transparent items-center justify-center hover:bg-white/10',
                classNames?.closeIcon
              )}
              onClick={onClose}
            >
              <CardPlus className="rotate-45" />
            </button>
          )}
          <div
            className={classes(
              'flex p-4 lg:p-8 overflow-hidden h-full w-full flex-1 flex-col',
              classNames?.content
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;

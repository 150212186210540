export const sourceOptions = [
  {
    label: 'All Source',
    value: '',
    icon: <img src="/icons/chain/SourceIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'PixelPort',
    value: 'PixelPort',
    icon: <img src="/icons/chain/SourceIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'HLGRH 2',
    value: 'HLGRH_2',
    icon: <img src="/icons/chain/SourceIcon.svg" alt="baseIcon" width={38} />,
  },
];

export const chainsOptions = [
  {
    label: 'All chains',
    value: '',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'polygon',
    value: 'polygon',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'Ethereum',
    value: 'ethereum',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'binance',
    value: 'binance',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'Astar',
    value: 'astar',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'Avalanche',
    value: 'avalanche',
    icon: <img src="/icons/chain/chainIcon.svg" alt="baseIcon" width={38} />,
  },
];

export const pricingOptions = [
  {
    label: 'High to Low',
    value: 'highToLow',
    icon: <img src="/icons/chain/blackBgDoller.svg" alt="baseIcon" width={38} />,
  },
  {
    label: 'Low to High',
    value: 'lowToHigh',
    icon: <img src="/icons/chain/blackBgDoller.svg" alt="baseIcon" width={38} />,
  },
];

export const filtersStyle = {
  selector: 'p-0 h-auto justify-between w-full text-base border-[1px] px-2 py-2 rounded-full',
  base: 'w-full md:w-[224px]',
  container: 'border-lightBlack/[0.17] mt-[10px] rounded-t-2xl md:rounded-2xl',
  option: 'text-lightBlack p-0 first:md:pt-0 md:pb-4 last:md:pb-0',
  optionContainer: 'gap-4',
  handlerIcon:
    'flex flex-row justify-center items-center p-[12px] h-[40px] w-[40px] rounded-full bg-[#f8f8f8]',
};

import { useEffect, useRef, useState } from 'react';

import { Header, TypeFormButton } from 'components';
import { DoubleRightArrow, RoundTickBlack, TurnOff } from 'components/Icons';
import { useWindowSize } from 'hooks';
import { classes } from 'utils';

import { GetStartedButton } from './GetStartedButton';

const FeaturesData = [
  {
    text: 'Next-Gen Multichain NFTs',
    subText:
      'Inspired by the innovation of ERC-404, Pixelport is here to take your NFTs to the next level. We’re not just redefining digital ownership, we’re giving it a whole new playground. With Pixelport, you can fractionalize your NFTs, zip them across multiple chains and dive into everything from digital art to gaming, real estate, and even governance. It’s like giving your digital assets a passport to explore an ocean of possibilities.',
    Icon: DoubleRightArrow,
    bgImage: '/newDesign/GIFs/nextgenMultichain.gif',
  },
  {
    text: 'Simplifying Cross-Chain NFTs',
    subText:
      'Pixelport’s SDK makes building cross-chain fractionalized NFTs a breeze for developers. Whether you’re coding up the next big dApp or just dipping your toes into the blockchain waters, our tools make integration smooth and straightforward.',
    Icon: RoundTickBlack,
    bgImage: '/newDesign/GIFs/simpleCrossChain.gif',
  },
  {
    text: 'Powered by Analog GMP',
    subText:
      'Under the hood, every Pixelport cross-chain transfer runs on the power of Analog’s Generic Message Passing (GMP) protocol. Analog GMP minimizes gas costs, keeping fees low and transactions quick, so you can move your assets with confidence and without breaking the bank. It’s the secret sauce that makes Pixelport’s cross-chain magic happen',
    Icon: TurnOff,
    bgImage: '/newDesign/GIFs/PowerdByAnalog.gif',
  },
];

// eslint-disable-next-line import/no-unused-modules
export function HowItWorks() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;

  const [isInView, setIsInView] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.1, // 10% of the div needs to be visible to trigger
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col-reverse xl:flex-row lg:gap-[78px] md:gap-[30px] gap-8 items-start md:justify-between w-full"
      id="how-it-works"
    >
      <div
        className={`grid grid-rows-[repeat(${
          FeaturesData.length
        },_${40}vw)] gap-[22px] md:gap-[42px] pb-[180%] xxs:pb-[130%] md:pb-[200px] px-4 xxs:px-8 z-20`}
        ref={divRef}
      >
        {FeaturesData.map(({ subText, text, Icon, bgImage }, index) => {
          return (
            <div
              key={`feature-${index}`}
              className={classes(
                'w-full md:w-[75%] xl:w-[500px] min-h-[520px] md:min-h-[700px] rounded-[20px] px-[30px] md:px-[50px] py-[30px] md:py-[38px] md:backdrop-blur-[80px] shadow-navbar-shadow border border-[#83838333] bg-[#ffffff] md:bg-[#ffffff7d]',
                'sticky z-10 bg-white bg-opacity-70 backdrop-blur-[10px]'
              )}
              style={{
                top: isBelowMd ? `${10 + index * 90}px` : `${10 + index * 90}px`,
                backgroundImage: isInView ? `url(./${bgImage})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: '96%',
                backgroundSize: '80%',
              }}
            >
              <div className="flex items-center gap-[12px] md:gap-[21px] pb-0 xxs:pb-[12px] md:pb-[30px]">
                <div>
                  <Icon />
                </div>
                <div className="text-lg md:text-2xl font-semibold font-Jakarta">{text}</div>
              </div>
              <div className="text-[14px] md:text-lg font-Jakarta font-normal mt-[14px] md:mt-[0px]">
                {subText}
              </div>
            </div>
          );
        })}
        <div className="py-2"></div>
      </div>
      <div className="flex flex-col gap-2 md:gap-2 2xl:gap-0 max-w-[600px] mt-16 z-10 text-white md:pb-[60px] xl:pb-[200px] mx-4 xxs:mx-8 xl:px-0 xl:sticky top-[5%]">
        <div>
          <Header
            classNames={{
              header:
                'text-4xl xxs:text-5xl md:text-[64px] font-["Arcade"] normal-case pixelport-text-shadow whitespace-nowrap',
            }}
          >
            HOW PIXELPORT
          </Header>
          <Header
            classNames={{
              header:
                'text-4xl xxs:text-5xl md:text-[64px] font-["Arcade"] normal-case pixelport-text-shadow whitespace-nowrap 2xl:mt-[-10px]',
            }}
          >
            NFTs WORK
          </Header>
        </div>
        <Header
          tag={'p'}
          classNames={{
            header: 'text-xs md:text-lg normal-case font-Jakarta font-normal',
          }}
        >
          Beneath the fun and games, Pixelport packs some serious tech. Its infrastructure makes NFT
          creation, trading, and management across blockchains simple. Whether minting,
          fractionalizing, or transferring assets Pixelport ensures everything runs smoothly for
          you.
        </Header>
        <TypeFormButton formId="uvT7QzSD" as="div" className="mt-8">
          <GetStartedButton
            title="get early access"
            arrowStroke="black"
            classNames={{
              base: '!bg-black !text-white',
              container: 'bg-white',
              text: 'font-Jakarta font-semibold',
            }}
          />
        </TypeFormButton>
      </div>
    </div>
  );
}

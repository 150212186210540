const NoImageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full aspect-w-1 aspect-h-1">
      <h2 className="text-center md:text-[40px] text-[32px] mb-2 text-black uppercase font-secondary leading-10">
        No image Found
      </h2>
    </div>
  );
};
export default NoImageFound;
